import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: ReactNode;
  okText?: string;
  cancelText?: string;
  deleteButton?: boolean;
  loading?: boolean;
  onCloseIcon?: () => void;
  thirdButton?: boolean;
  thirdButtonText?: string;
  actionThirdButton?: () => void;
  closeIcon?: boolean;
  contentBold?: boolean;
}

const ConfirmationDialog = (props: Props) => {
  const {
    open,
    onClose,
    onConfirm,
    onCloseIcon,
    title,
    content,
    okText = 'Ok',
    cancelText = 'Cancel',
    deleteButton = false,
    loading = false,
    thirdButton = false,
    thirdButtonText = 'No save',
    actionThirdButton,
    contentBold=false,
    closeIcon = true,
  } = props;

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={() => {}}
      PaperProps={{
        sx: {
          maxWidth: '500px',
          minWidth: '350px',
          paddingY: '10px',
        },
      }}
    >
      <DialogTitle fontWeight={700}>
        <Box width={'90%'}>{title}</Box>
        {closeIcon && (
          <Close
            style={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
            onClick={onCloseIcon ? onCloseIcon : onClose}
          />
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
        sx={{
          fontWeight: contentBold ? 700 : 400,
        }}
        >{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {thirdButton && (
          <Button
            onClick={actionThirdButton}
            variant="outlined"
            sx={{ color: 'text.primary' }}
          >
            {thirdButtonText}
          </Button>
        )}
        <Button onClick={onClose} variant="outlined" sx={{ color: 'text.primary' }}>
          {cancelText}
        </Button>
        <LoadingButton
          variant="contained"
          color={deleteButton ? 'secondary' : 'primary'}
          onClick={onConfirm}
          loading={loading}
        >
          {okText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
