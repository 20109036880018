import { Dialog, DialogContent } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import {
  ArticleType,
  Insight,
  PostContentMedia,
  Sentiment,
} from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostContext } from '../../context/PostContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useStreamedCompletion } from '../../hooks/useStreamedCompletion';
import { getMimeType } from '../../utils';
import PostEditorModal from '../PostComponents/PostEditorModal';
import AddPerspectiveInsightModal from './AddPerspectiveInsightModal';
import InsightView from './InsightView';

interface Props {
  insight: Insight | null;
  onClose: () => void;
  handleNextInsight: () => void;
  handlePreviousInsight: () => void;
  handleCreateOrUpdateInsight: (
    insightInput: Insight,
    sentiment?: Sentiment,
    favorite?: boolean,
  ) => void;
  disabledButtonBack: boolean;
  disabledButtonNext: boolean;
}

const InsightModal = ({
  insight,
  onClose,
  handleNextInsight,
  handlePreviousInsight,
  handleCreateOrUpdateInsight,
  disabledButtonBack,
  disabledButtonNext,
}: Props) => {
  const { isMobile } = useContext(DeviceContext);
  const [showModalAddPerspective, setShowModalAddPerspective] = useState<Insight | null>(
    null,
  );
  const [openPostEditor, setOpenPostEditor] = useState<boolean>(false);
  const { postState, editor } = useContext(PostContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);

  const cursorPositionRef = useRef<number>();

  const defaultMedia: PostContentMedia[] =
    insight?.imgUrl || insight?.videoUrl
      ? [
          {
            url: insight?.imgUrl || insight?.videoUrl,
            type: getMimeType((insight?.imgUrl || insight?.videoUrl)!),
          },
        ]
      : [];

  const insightInput = `Title: "${insight?.title}"\n\nDescription: "${insight?.description}"\n\nContent: "${insight?.content}"\n\nLink: "${insight?.link}"`;

  const [fetchDataCreateDraft] = useStreamedCompletion(
    (content) => {
      if (content && editor) {
        editor.commands.insertContentAt(
          cursorPositionRef.current || 0,
          content.replaceAll('\n', '<br>') || '',
        );

        postState?.handlers.handleBodyChange(editor.getHTML(), editor.getJSON(), {
          name: 'FROM_INSIGHT',
          color: '#FFFFFF',
          internal: true,
        });

        cursorPositionRef.current = editor.state.selection.anchor;
      }
    },
    (error) => {
      setErrorMessage(error);
    },
    () => {
      editor?.setEditable(true);
    },
  );

  const handleCreatePostDraft = async (
    insightType: ArticleType,
    topicContext: string,
    media: PostContentMedia[],
  ) => {
    let chainId = '66310bbff1de1c64651db3a5';
    if (insightType === ArticleType.Article) {
      chainId = '66310baff1de1c64651db3a4';
    }

    setOpenPostEditor(true);
    editor?.setEditable(false);

    cursorPositionRef.current = 0;
    await fetchDataCreateDraft({
      chainId,
      brandId: postState?.post.brandId || undefined,
      userId: user?._id || undefined,
      input:
        'Create a social media post for Linkedin based on this article: ' + topicContext,
    });

    postState?.handlers.handleUploadMedia(media);
  };

  if (openPostEditor) {
    return (
      <PostEditorModal
        onClose={() => {
          setOpenPostEditor(false);
          editor?.commands.clearContent();
          postState?.handlers.handleBodyChange('', '', {
            name: 'FROM_INSIGHT',
            color: '#FFFFFF',
            internal: true,
          });
          onClose();
        }}
        isInsight
      />
    );
  }

  if (showModalAddPerspective) {
    return (
      <AddPerspectiveInsightModal
        insight={showModalAddPerspective}
        onClose={() => setShowModalAddPerspective(null)}
        handleCreatePostDraft={handleCreatePostDraft}
      />
    );
  }

  return (
    <Dialog
      open={true}
      fullScreen={isMobile}
      disableEscapeKeyDown
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ margin: 'none' }}
      onClose={onClose}
      data-testid="insight-modal"
      scroll="body"
      maxWidth="md"
      PaperProps={{
        style: {
          maxHeight: isMobile ? '100vh' : 'calc(100vh - 64px)',
        },
      }}
    >
      <DialogContent>
        <InsightView
          insight={insight}
          onClose={onClose}
          handleNextInsight={handleNextInsight}
          handlePreviousInsight={handlePreviousInsight}
          handleCreateOrUpdateInsight={handleCreateOrUpdateInsight}
          handleCreatePostDraft={(insight, addPerspective) => {
            if (addPerspective) {
              setShowModalAddPerspective(insight);
              return;
            }

            handleCreatePostDraft(
              insight.type || ArticleType.Article,
              insightInput,
              defaultMedia,
            );
          }}
          disabledButtonBack={disabledButtonBack}
          disabledButtonNext={disabledButtonNext}
        />
      </DialogContent>
    </Dialog>
  );
};

export default InsightModal;
