import {
  CalendarToday,
  Close,
  Comment,
  Favorite,
  FavoriteBorder,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Document, Page } from 'react-pdf';
import {
  Post,
  PostLifecycleState,
  PostType,
  Tag,
  UserRole,
} from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import '../../styles/PostCard.css';
import { formatDate, timeAgo, timeString } from '../../utils';
import Avatar from '../Avatar/Avatar';
import PostTags from './PostTags';
import { useTranslation } from 'react-i18next';

interface Props {
  post: Post;
  onClickFavoriteButton?: () => void;
  onClickDeleteButton?: () => void;
  onClickShareButton?: () => void;
  setPostsToDelete: (id: string[]) => void;
  postsToDelete: string[];
  inboxLink?: boolean;
  previewShareInbox?: boolean;
}

const PostCard = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { user, refetchCurrentUser } = useContext(AuthContext);

  const {
    post,
    onClickFavoriteButton,
    onClickDeleteButton,
    onClickShareButton,
    inboxLink,
    previewShareInbox,
  } = props;
  const [loadImage, setLoadImage] = useState(!!!post.content?.media?.length);
  const isAdvocacy = post.type === PostType.Advocacy;
  const isPosted = post.lifecycleState === PostLifecycleState.Posted;
  const isBlog = post.type === PostType.Blog;
  const isCorporate = post.type === PostType.Corporate;
  const isReadyToShare = post.type === PostType.ReadyToShare;
  const isInsights = post.type === PostType.Insights;

  let title = '';
  if (
    user?.role.includes(UserRole.BrandManager) ||
    user?.role.includes(UserRole.LimitedBrandManager) ||
    inboxLink
  ) {
    title = post.brandName || t('Unknown');
  } else if (isAdvocacy) title = t('Advocacy');
  else if (isBlog) title = t('Blog');
  else if (isCorporate) title = t('Corporate');
  else if (post.createdById === user?._id && post.brandId === user?.brandId)
    title = t('My post');
  else if (isReadyToShare) title = t('For you');
  else if (isInsights) title = t('Insights');

  const subtitle = (() => {
    if (isPosted) {
      const date = new Date(post.postedAt);
      return `for ${formatDate(date)}, ${timeString(date)}`;
    } else if (post.schedule && post.lifecycleState === PostLifecycleState.Scheduled) {
      const date = new Date(post.schedule);
      return `for ${formatDate(date)}, ${timeString(date)}`;
    } else if (post.schedule) {
      const date = new Date(post.schedule);
      return `for ${formatDate(date)}`;
    }
  })();

  const preview = post.content?.preview || post.advocacy?.parentPost?.content?.preview;
  const thumbnail =
    post.content?.thumbnail || post.advocacy?.parentPost?.content?.thumbnail || null;

  let media = post.content?.media || [];
  if (isAdvocacy && !post.content && post.advocacy?.parentPost?.content?.media) {
    media = post.advocacy?.parentPost?.content?.media;
  }

  const theme = useTheme();

  const Preview = styled('div')({
    '& > p': {
      marginTop: 0,
    },
  });

  const notifications = [];

  if (
    (post.unreadMessagesCount || 0) > 0 &&
    [UserRole.PostChatUser, UserRole.BrandManager, UserRole.LimitedBrandManager].some(
      (r) => user?.role.includes(r),
    )
  ) {
    notifications.push(
      <Stack
        key="messages-notification"
        direction={'row'}
        alignItems={'center'}
        gap={1}
        justifyContent={'right'}
      >
        <Comment color="primary" style={{ fontSize: 15 }} />
        <Typography
          color="primary.main"
          variant="body2"
          fontSize={'14px'}
          fontWeight={'bold'}
        >
          {`${post.unreadMessagesCount}`}
        </Typography>
      </Stack>,
    );
  }

  return (
    <Card
      sx={{
        border: 'solid 1px #EBEBEB',
        minWidth: { xs: '100%', sm: '100%' },
      }}
      data-testid="post-card"
    >
      <CardHeader
        sx={{ paddingBottom: 1 }}
        action={
          !isInsights && (
            <>
              {post.brandId === user?.brandId && (
                <IconButton onClick={onClickFavoriteButton} data-testid="favorite-button">
                  {post.favorite ? (
                    <Favorite data-testid="favorite-filled-icon" color="primary" />
                  ) : (
                    <FavoriteBorder data-testid="favorite-outlined-icon" />
                  )}
                </IconButton>
              )}

              {post.lifecycleState !== PostLifecycleState.Posted &&
              post.lifecycleState !== PostLifecycleState.Discarded &&
              !previewShareInbox ? (
                <IconButton onClick={onClickDeleteButton} data-testid="delete-button">
                  <Close />
                </IconButton>
              ) : null}
            </>
          )
        }
        title={
          <Typography
            variant="body2"
            fontSize="13px"
            dangerouslySetInnerHTML={{
              __html: `${t('Created by')} <b>${
                post.createdBy?.shellId !== post.shellId
                  ? 'magnettu'
                  : post?.createdBy?.firstName + ' ' + post?.createdBy?.lastName
              }</b> ${
                i18n.language === 'es'
                  ? `${t('ago')} ${timeAgo(
                      new Date(post.publishedOn || post.createdOn),
                      t,
                    )}`
                  : `${timeAgo(new Date(post.publishedOn || post.createdOn), t)} ${t(
                      'ago',
                    )}`
              }`,
            }}
          />
        }
        titleTypographyProps={{
          fontSize: '14px',
        }}
      />
      <CardContent style={{ paddingTop: 0 }}>
        <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={1} alignItems="center">
            {(user?.role.includes(UserRole.BrandManager) ||
              user?.role.includes(UserRole.LimitedBrandManager) ||
              inboxLink) && (
              <Avatar
                avatar={post?.brand?.account?.avatar || ''}
                name={post?.brandName || ''}
                size={35}
                backgroundColor={post?.brand?.backgroundColor || '#BBB'}
              />
            )}
            <Box>
              <Typography
                sx={{
                  variant: 'body2',
                  color: 'text.primary',
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
              >
                {title}
              </Typography>
              <Stack direction="row" alignItems="center" gap={0.5}>
                {subtitle && (
                  <CalendarToday sx={{ width: 18, height: 18, color: 'primary.main' }} />
                )}
                <Typography
                  sx={{
                    variant: 'body2',
                    fontWeight: 'normal',
                    fontSize: '13px',
                  }}
                >
                  {subtitle}
                </Typography>
              </Stack>
            </Box>
          </Stack>
          <Stack flexDirection="row" gap={0.5}>
            <Chip
              size="small"
              sx={{
                backgroundColor: post.lifecycleStateDisplay?.color!,
                width: 'fit-content',
              }}
              label={t(post?.lifecycleStateDisplay?.name || '')}
            />
            {post.isIdea && (
              <Chip
                size="small"
                sx={{
                  background: '#FBBF253D',
                }}
                label="Idea"
              />
            )}
          </Stack>
        </Stack>
        <Stack direction={'column'} gap={0.5} marginBottom={0.5} justifyContent={'right'}>
          {notifications}
        </Stack>

        <Divider sx={{ my: 1 }}></Divider>
        {post?.title && (
          <Typography
            fontWeight="bold"
            textAlign="left"
            marginBottom={0.5}
            color="#212121"
          >
            {post?.title}
          </Typography>
        )}
        <Preview dangerouslySetInnerHTML={{ __html: preview || '' }}></Preview>

        <Link mb={2}>{post?.content?.hashtags}</Link>
      </CardContent>
      {!!media.length && (
        <>
          {media[0].type?.includes('pdf') ? (
            <CardMedia
              sx={{
                marginBottom: 1,
                height: 'auto',
                marginX: 'auto',
                width: '100%',
                maxWidth: '100%',
              }}
              data-testid="post-card-media"
              children={
                <>
                  <Box
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      padding: 3,
                      width: '50px',
                      justifyContent: 'center',
                      content: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Typography color={'white'} fontSize={'12px'} fontWeight={600}>
                      PDF
                    </Typography>
                  </Box>
                  <Document file={media[0].url} loading={<CircularProgress />}>
                    <Page pageNumber={1} onLoadSuccess={() => setLoadImage(true)} />
                  </Document>
                </>
              }
            />
          ) : (
            <CardMedia
              component={media[0]?.type?.startsWith('video') ? 'video' : 'img'}
              controls
              autoPlay
              playsInline
              muted
              loop
              image={media[0]?.url || undefined}
              sx={{ marginBottom: 1, height: loadImage ? 'auto' : '100px' }}
              data-testid="post-card-media"
              onLoad={() => setLoadImage(true)}
              onLoadedData={() => setLoadImage(true)}
            />
          )}
        </>
      )}

      {!!media.length && !loadImage && (
        <Skeleton variant="rectangular" height={300} sx={{ marginTop: '-100px' }} />
      )}

      {thumbnail && !media.length && (
        <Box marginBottom="10px">
          <Card
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              background: '#ECF3F9',
              boxShadow: 0,
              borderRadius: '4px',
              padding: '9px 14px 9px 14px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (thumbnail?.source) {
                window.open(thumbnail.source, '_blank');
              }
            }}
          >
            <Stack flexDirection="row" alignItems="center" justifyContent="center">
              <CardMedia
                component="img"
                sx={{ width: 110, height: 100, borderRadius: 2 }}
                image={thumbnail.media?.url || ' '}
                alt={thumbnail?.title as string}
              />
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                  {thumbnail?.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {thumbnail?.source?.replace(/https?:\/\/(www\.)?|\/+$/g, '')}
                </Typography>
              </CardContent>
            </Stack>
          </Card>
        </Box>
      )}

      <Box paddingX="10px">
        {post.tags && post.tags.length > 0 && <PostTags tags={post.tags as Tag[]} />}
      </Box>

      <CardActions
        sx={{
          paddingX: '10px',
          paddingBottom: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'start',
        }}
      >
        <Button
          fullWidth
          sx={{ backgroundColor: '#F5F5F5', border: '1px solid #E0E0E0' }}
          onClick={() => {
            onClickShareButton?.();
            refetchCurrentUser();
          }}
          data-testid="share-button"
          disabled={post.lifecycleState === PostLifecycleState.Posting}
        >
          {post.lifecycleState === PostLifecycleState.Posting ? (
            <Typography fontWeight="bold">Posting...</Typography>
          ) : (
            <Typography fontWeight="bold">
              {t('View')} {!isInsights && t('& Edit')}
            </Typography>
          )}
        </Button>
      </CardActions>
    </Card>
  );
};

export default PostCard;
