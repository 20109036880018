import React, { useState } from 'react';
import {
  Dialog,
  Box,
  IconButton,
  Stack,
  Typography,
  TextField,
  Button,
  Divider,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import {
  CalendarToday,
  Close,
  GridView,
  List,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { GET_POSTS_BY_SHELL } from '../../graphql/queries';
import { GET_BRAND } from '../../graphql/queries';
import MasonryContentBoard from '../Masonry/MasonryContentBoard';
import PostsTeamTable from '../Tables/PostsTeamTable';
import MyCalendar from '../Calendar/Calendar';
import { useTranslation } from 'react-i18next';
import {
  PostLifecycleState,
  OrderByField,
  OrderByDirection,
} from '../../__generated__/graphql';
import { CalendarEvent } from '../../types';

interface ShareInboxSettingsModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (password: string) => void;
  brandId?: string;
  mode: 'set' | 'enter';
  title?: string;
  error?: string;
}

enum ViewOptions {
  grid,
  list,
  calendar,
}

const LIMIT = 10;

const ShareInboxSettingsModal: React.FC<ShareInboxSettingsModalProps> = ({
  open,
  onClose,
  onConfirm,
  brandId,
  mode,
  title,
  error,
}) => {
  const { t } = useTranslation();
  const [view, setView] = useState<ViewOptions>(ViewOptions.grid);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [localError, setLocalError] = useState('');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(LIMIT);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [orderBy, setOrderBy] = useState({
    field: OrderByField.CreatedOn,
    direction: OrderByDirection.Desc,
  });

  const { data, loading } = useQuery(GET_POSTS_BY_SHELL, {
    variables: {
      filter: {
        ...(brandId ? { brandIds: [brandId] } : {}), // Only include brandIds if brandId is valid
        lifecycleStates: [
          PostLifecycleState.PendingApproval,
          PostLifecycleState.Ready,
          PostLifecycleState.Approved,
          PostLifecycleState.Scheduled,
          PostLifecycleState.Discarded,
          PostLifecycleState.Draft,
        ],
      },
      orderBy,
      page,
      limit: view === ViewOptions.grid ? LIMIT : view === ViewOptions.list ? limit : -1,
    },
    fetchPolicy: 'network-only',
    skip: mode === 'enter' || !brandId, // Skip query if mode is 'set'
  });

  const { data: dataBrand, refetch: refetchBrandData } = useQuery(GET_BRAND, {
    variables: {
      brandId: brandId!,
    },
    skip: !brandId,
  });

  const posts = data?.postsByShell?.posts || [];

  const handleConfirm = () => {
    // If passwords do not match, show error
    if (mode === 'set' && password !== confirmPassword) {
      setLocalError('Passwords do not match');
      return;
    }

    // Check if a password already exists for the brand
    if (dataBrand?.getBrand?.shareLink?.share) {
      setOpenConfirmationModal(true); // Show confirmation modal
    } else {
      // If no existing password, proceed to confirm
      setLocalError('');
      onConfirm(password);
      setPassword('');
      setConfirmPassword('');
      if (mode !== 'enter') {
        refetchBrandData(); // Refetch only if not in 'enter' mode
      }
    }
  };

  const handleConfirmationYes = () => {
    setOpenConfirmationModal(false);
    setLocalError('');
    onConfirm(password);
    if (mode !== 'enter') {
      refetchBrandData(); // Refetch only if not in 'enter' mode
    }
    setPassword('');
    setConfirmPassword('');
  };

  const handleConfirmationNo = () => {
    setOpenConfirmationModal(false); // Close confirmation modal without updating
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const events: CalendarEvent[] = posts.map((post) => ({
    id: post._id,
    date:
      post.lifecycleState === PostLifecycleState.Posted
        ? post.publishedOn
        : post.schedule,
    title: post.title || 'No Title',
    postingAccountName: post.brand?.account?.name || 'Unknown Brand',
    avatar: post.brand?.account?.avatar || '',
    brandId: post.brandId || '',
    lifecycleState: post.lifecycleState || PostLifecycleState.Draft,
    lifecycleStateDisplay: post.lifecycleStateDisplay || {
      name: 'Unknown',
      color: '#ccc',
    },
    isIdea: post.isIdea || false,
    preview: post.content?.preview || '',
    backgroundColor: post.lifecycleStateDisplay?.color || '#f0f0f0',
  }));

  return (
    <Stack>
      <Dialog
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
      >
        <Box sx={{ padding: '20px', textAlign: 'center' }}>
          <Typography fontWeight="bold" variant="h6" mb={2}>
            {t('There is already a password for this brand. Do you want to change it?')}
          </Typography>
          <Box display="flex" justifyContent="center" gap={2}>
            <Button
              variant="outlined"
              onClick={handleConfirmationNo} // Close modal without changing
            >
              {t('No')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirmationYes}>
              {t('Yes')}
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={mode !== 'enter'} // Full width for 'set', smaller for 'enter'
        maxWidth={mode === 'enter' ? 'sm' : 'lg'} // Adjust max width based on mode
        sx={{
          '& .MuiDialog-paper': {
            width: mode === 'enter' ? '500px' : '100%', // For smaller mode, limit width
            height: mode === 'enter' ? '280px' : '100%', // Auto height for 'enter', full for 'set'
            overflowY: mode === 'enter' ? 'hidden' : 'auto', // Allow scrolling if content overflow
          },
        }}
      >
        {/* Modal Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid #E0E0E0',
          }}
        >
          <Typography variant="h6" fontWeight="700">
            {title || `Share ${dataBrand?.getBrand?.name} Inbox`}
          </Typography>
          {mode !== 'enter' && (
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          )}
        </Box>

        {/* Modal Content */}
        <Stack direction="row" sx={{ height: '100%' }}>
          {/* Left Panel: Post Preview */}
          {mode !== 'enter' && (
            <Box
              sx={{
                flex: 2,
                overflowY: 'auto',
                padding: '16px',
                borderRight: '1px solid #E0E0E0',
              }}
            >
              <Stack direction="row" justifyContent="space-between" mb={2}>
                <Typography variant="h6" fontWeight="700">
                  Preview
                </Typography>
                <Stack direction="row" gap={1}>
                  <IconButton
                    onClick={() => setView(ViewOptions.grid)}
                    color={view === ViewOptions.grid ? 'primary' : 'default'}
                    sx={{
                      backgroundColor:
                        view === ViewOptions.grid
                          ? 'rgba(0, 123, 255, 0.1)'
                          : 'transparent', // Highlight background for selected view
                      '&:hover': {
                        backgroundColor: 'rgba(0, 123, 255, 0.2)', // Lighter highlight on hover
                      },
                    }}
                  >
                    <GridView />
                  </IconButton>
                  <IconButton
                    onClick={() => setView(ViewOptions.list)}
                    color={view === ViewOptions.list ? 'primary' : 'default'}
                    sx={{
                      backgroundColor:
                        view === ViewOptions.list
                          ? 'rgba(0, 123, 255, 0.1)'
                          : 'transparent', // Highlight background for selected view
                      '&:hover': {
                        backgroundColor: 'rgba(0, 123, 255, 0.2)', // Lighter highlight on hover
                      },
                    }}
                  >
                    <List />
                  </IconButton>

                  <IconButton
                    onClick={() => setView(ViewOptions.calendar)}
                    color={view === ViewOptions.calendar ? 'primary' : 'default'}
                    sx={{
                      backgroundColor:
                        view === ViewOptions.calendar
                          ? 'rgba(0, 123, 255, 0.1)'
                          : 'transparent', // Highlight background for selected view
                      '&:hover': {
                        backgroundColor: 'rgba(0, 123, 255, 0.2)', // Lighter highlight on hover
                      },
                    }}
                  >
                    <CalendarToday />
                  </IconButton>
                </Stack>
              </Stack>
              {loading ? (
                <CircularProgress />
              ) : view === ViewOptions.grid ? (
                <MasonryContentBoard
                  posts={posts}
                  postsToDelete={[]}
                  setPostsToDelete={() => {}}
                  inboxLink={false}
                  previewShareInbox={true}
                />
              ) : view === ViewOptions.list ? (
                <PostsTeamTable
                  posts={posts}
                  page={page}
                  limit={limit}
                  setLimit={setLimit}
                  setOrderBy={setOrderBy}
                  orderBy={orderBy}
                  onPageChange={setPage}
                  total={data?.postsByShell?.total || 0}
                  handleEditPost={() => {}}
                  previewShareInbox={true}
                />
              ) : (
                <MyCalendar
                  events={events || []}
                  handleEditPost={() => {}}
                  handleOpenNewPost={() => {}}
                  handleDiscard={() => {}}
                />
              )}
            </Box>
          )}

          {/* Right Panel: Password Settings */}
          <Box
            sx={{
              flex: 1,
              padding: '16px',
              position: 'sticky', // Make it sticky
              top: 0, // Stick to the top of the modal
              height: 'calc(100vh - 64px)', // Ensure it takes the full height of the viewport
              overflowY: 'auto', // Allow scrolling within the panel if content overflows
              backgroundColor: 'white', // Optional: Ensure consistent background
              zIndex: 1, // Ensure it stays above other content
            }}
          >
            {mode !== 'enter' && (
              <Typography variant="h6" fontWeight="700" mb={2}>
                Set a Password for Sharing
              </Typography>
            )}

            <Divider sx={{ mb: 2 }} />
            <Stack spacing={2}>
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                error={!!localError || !!error}
                helperText={localError || error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {mode === 'set' && (
                <TextField
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  error={!!localError}
                  helperText={localError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                disabled={!password || (mode === 'set' && !confirmPassword)}
                fullWidth
              >
                {mode === 'set' ? 'Copy Link' : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default ShareInboxSettingsModal;
