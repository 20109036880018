import { useQuery } from '@apollo/client';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import {
  PerformingContent as PerformingContentType,
  Tag,
  UserRole,
} from '../../__generated__/graphql';
import PerformingContentTable from '../../components/Tables/PerformingContentTable';
import { AuthContext } from '../../context/AuthContext';
import { GET_METRICS_PERFORMING_CONTENT } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';

const PerformingContent = () => {
  const { t } = useTranslation();
  const init = new Date();
  init.setDate(init.getDate() - 30);
  const end = new Date();

  const { user } = useContext(AuthContext);

  const [dateInit, setDateInit] = useState<Date>(init);
  const [dateEnd, setDateEnd] = useState<Date>(end);
  const [metricsShell, setMetricsShell] = useState<PerformingContentType[]>([]);

  const changeDateFilter = (dateInitInput: string, dateEndInput: string) => {
    const dateInitISO = new Date(dateInitInput);
    const dateEndISO = new Date(dateEndInput);

    if (dateInit === dateInitISO && dateEnd === dateEndISO) return;
    setDateInit(dateInitISO);
    setDateEnd(dateEndISO);
  };

  const {loading: loadingMetricsTeam } = useQuery(
    GET_METRICS_PERFORMING_CONTENT,
    {
      fetchPolicy: 'network-only',
      variables: {
        shellId: user?.shellId as string,
        dateInit: dateInit,
        dateEnd: dateEnd,
      },
      skip:
        !user ||
        (!user.role.includes(UserRole.BrandManager) &&
          !user.role.includes(UserRole.LimitedBrandManager)),
      onCompleted: (data) => {
        if (data.getMetricsPerformingContent) {
          setMetricsShell(
            data.getMetricsPerformingContent.performingContent as PerformingContentType[],
          );
        }
      },
    },
  );

  const updateMetricsShellWithTags = (tags: Tag[], postId: string) => {
    const updatedMetricsBrand = metricsShell.map((metric) => {
      if (metric.postId === postId) {
        return { ...metric, tags: tags };
      }
      return metric;
    });
    setMetricsShell(updatedMetricsBrand);
  };

  return (
    <>
      {loadingMetricsTeam ? <LinearProgress /> : <Box height={4} />}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        spacing={2}
        p={4}
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography variant={'h4'} fontWeight="bold">
            {t('Content Table')}
          </Typography>
          <Typography variant={'body1'}>
            {t('Here you can see the best performing content of your brands.')}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" px={4}>
        <PerformingContentTable
          userOrShell="shell"
          loadingButton={loadingMetricsTeam}
          data={metricsShell}
          dateFilter={changeDateFilter}
          dateInit={dateInit}
          dateEnd={dateEnd}
          updateMetricsBrandWithTags={updateMetricsShellWithTags}
        />
      </Stack>
    </>
  );
};

export default PerformingContent;
