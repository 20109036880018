import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  AdvocacyPostInput,
  Brand,
  GetPostQuery,
  MessagesTeam,
  Post,
  PostContentMedia,
  PostHelperType,
  PostLifecycleState,
  PostType,
  RoleChat,
  Tag,
  Thumbnail,
  UserRole,
  WebSocketEventType,
} from '../__generated__/graphql';
import { AuthContext } from '../context/AuthContext';
import { SnackbarContext } from '../context/SnackbarContext';
import { WebSocketContext } from '../context/WebSocketContext';
import { DefaultErrorMessage, StatusCode } from '../errors/constants';
import {
  CHANGE_POST_TITLE,
  CREATE_ADVOCACY_POST,
  CREATE_POST,
  DELETE_ADVOCACY_POST,
  HARD_DELETE_POST,
  SELECT_IS_IDEA_POST,
  UPDATE_ADVOCACY_POST,
  UPDATE_CONTENT_POST,
  UPDATE_POST_DATE,
  UPDATE_POST_STATE,
  UPDATE_SCHEDULE_POST,
  UPDATE_TAGS,
} from '../graphql/mutations';
import {
  GET_ADVOCACY_PARENT_POSTS,
  GET_CURRENT_BRAND_POSTS,
  GET_POST,
} from '../graphql/queries';
import { client } from '../index';
import { getLifecycleStateDisplay } from '../types';
import { getFullName } from '../utils';
import useLinkedin from './useLinkedin';

export interface PostHandlers {
  handleBodyChange: (html: string, json: any, action?: Tag) => void;
  handleUploadMedia: (media: PostContentMedia[]) => void;
  handleUploadThumbnail: (thumbnail: Thumbnail) => void;
  handleDeleteMedia: (mediaIndex: number) => void;
  handleSaveAsDraft: ({
    callback,
    lifecycleState,
    schedule,
  }: {
    callback?: (post: Post) => void;
    lifecycleState?: PostLifecycleState;
    schedule?: Date;
  }) => void;
  handleDuplicate: (callback?: (post: Post) => void) => void;
  handleScheduleChange: (date: Date | null, callback?: () => void) => void;
  handleRemoveSchedule: (callback?: () => void) => void;
  handlePostOnLinkedin: (callback?: () => void) => void;
  handleUnpublishLinkedinPost: () => void;
  handleDeleteAdvocacy: (callback: () => void) => void;
  handleCreateAdvocacy: (callback?: () => void) => void;
  handleUpdateAdvocacy: (callback?: () => void) => void;
  handleRecipientsChange: (recipients: Brand[]) => void;
  handleToggleAdvocacyEditable: () => void;
  handleMagnetsChange: (value: number) => void;
  handleTurnIntoAdvocacy: () => void;
  handleAddTags: (tags: Tag[]) => void;
  handleRemoveTag: (tag: Tag) => void;
  handleUnreadMessagesChange: (unreadMessagesCount: number) => void;
  handleSendChatMessage: (message: string) => void;
  handleStateChange: (lifecycleState: PostLifecycleState) => void;
  handleDateChange: (date: Date | null) => void;
  handleCloseConfirmationPostsDialog: () => void;
  handleOpenConfirmationPostsDialog: () => void;
  handleOpenRequestApprovalDialog: () => void;
  handleCloseRequestApprovalDialog: () => void;
  handleHideThumbnail: () => void;
  handleShowThumbnail: () => void;
  handleErrorThumbnail: (value: boolean) => void;
  handleLoadingThumbnail: (value: boolean) => void;
  handlePostIdea: (isIdea: boolean) => void;
  handleTitleChange: (title: string) => void;
  handleDeletePost: (callback?: () => void) => void;
  handleShowErrorThumbnail: (value: boolean) => void;
  handleClosePost: () => void;
  handleAddHelper: (helperText: string, type: PostHelperType) => void;
}

export interface PostStatus {
  isPostLoaded: boolean;
  isNew: boolean;
  isSaving: boolean;
  isPosting: boolean;
  isUnposting: boolean;
  isPosted: boolean;
  isScheduled: boolean;
  isSubmitDisabled: boolean;
  isEditDisabled: boolean;
  isAdvocacy: boolean;
  isAdvocacyParent: boolean;
  isAdvocacyEditable: boolean;
  // isValid: boolean;
  isSavable: boolean;
  charsCount: number;
  userEditing: string | null;
  isCurrentUserEditing: boolean;
  isSaved: boolean;
  confirmationPostsDialogOpen: boolean;
  requestApprovalDialogOpen: boolean;
  showThumbnail: boolean;
  thumbnailData: Thumbnail | null;
  loadingThumbnail: boolean;
  errorThumbnail: boolean;
  showErrorThumbnail: boolean;
  initialPostBodyRef: React.MutableRefObject<string>;
}

export type UsePostError = { code: StatusCode; message?: string } | null;

export type UsePostReturnType = {
  post: Post;
  status: PostStatus;
  handlers: PostHandlers;
  advocacyPostInput: AdvocacyPostInput | null;
  error: UsePostError;
  postRef: React.MutableRefObject<Post>;
};

export const initialPost: Post = {
  _id: '',
  createdOn: new Date(),
  type: PostType.ReadyToShare,
  brandId: '',
  brandName: '',
  lifecycleState: PostLifecycleState.Draft,
  lifecycleStateDisplay: {
    name: 'Draft',
    color: '#E6E6E6',
    order: 1,
    isStateChangeable: true,
    isPostPostable: true,
  },
  magnets: 0,
  content: {
    body: '',
  },
};

const initialAdvoacyPostInput: AdvocacyPostInput = {
  recipients: [],
  content: {
    body: '',
  },
  editable: true,
  magnets: 0,
  shellId: '',
  shellName: '',
};

const usePost = (
  postId?: string | null,
  newPostOptions = {},
  isLink = false,
  isLinkInbox = false,
): UsePostReturnType => {
  const { socket } = useContext(WebSocketContext);
  const { setSuccessMessage } = useContext(SnackbarContext);
  // const { editor } = useContext(PostContext);
  const { user, anonymousId } = useContext(AuthContext);

  const [post, setPost] = useState<Post>({ ...initialPost, ...newPostOptions });
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [showThumbnail, setShowThumbnail] = useState<boolean>(true);
  const [advocacyPostInput, setAdvocacyPostInput] = useState<AdvocacyPostInput>(
    initialAdvoacyPostInput,
  );
  const [userEditing, setUserEditing] = useState<string | null>(user?._id || null);
  const [isPosting, setIsPosting] = useState(false);
  const [confirmationPostsDialogOpen, setConfirmationPostsDialogOpen] =
    useState<boolean>(false);
  const [requestApprovalDialogOpen, setRequestApprovalDialogOpen] =
    useState<boolean>(false);
  const [isUnposting, setIsUnposting] = useState(false);
  const [thumbnailData, setThumbnailData] = useState<Thumbnail | null>(null);
  const [loadingThumbnail, setLoadingThumbnail] = useState(false);
  const [errorThumbnail, setErrorThumbnail] = useState(false);
  const [showErrorThumbnail, setShowErrorThumbnail] = useState(false);

  const postRef = useRef<Post>({ ...initialPost, ...newPostOptions });

  const initialPostBodyRef = useRef<string>('');
  const initialPostMediaRef = useRef<string>('');
  const initialPostThumbnailRef = useRef<string>('');
  const initialPostTitleRef = useRef<string>('');

  const onPostReceived = (data: GetPostQuery) => {
    if (data.post?.content?.thumbnail) {
      setThumbnailData(data.post?.content?.thumbnail);
    }

    if (data.post?.type === PostType.AdvocacyParent) {
      setAdvocacyPostInput({
        ...advocacyPostInput,
        recipients:
          data.post?.advocacy?.recipients?.map((recipient) => ({
            brandId: recipient._id!,
            brandName: recipient.name || '',
            ...(recipient.user && {
              userFirstName: recipient.user.firstName,
              userFullName: getFullName(recipient.user),
              userId: recipient._id,
              email: recipient.user.email,
              notifications: recipient.user.notifications?.email,
              language: recipient.user.language,
            }),
          })) || [],
        editable: data.post?.advocacy?.editable,
        magnets: data.post?.magnets || 0,
      });
    }

    const newPost = {
      ...data.post,
      content: {
        ...data.post?.advocacy?.parentPost?.content,
        ...data.post?.content,
      },
    };

    setPost(newPost as Post);
    postRef.current = { ...(newPost as Post) };
    initialPostBodyRef.current = newPost.content.body!;
    initialPostMediaRef.current = (newPost.content.media || [])
      .map((media) => media.url)
      .join(',');
    initialPostThumbnailRef.current = newPost.content.thumbnail?.source!;
    initialPostTitleRef.current = newPost.title || '';
  };

  const [getPost] = useLazyQuery(GET_POST, {
    variables: { postId: post._id },
    fetchPolicy: 'network-only',
    onCompleted: onPostReceived,
  });

  const handleSocketMessages = useCallback(
    (event: string, data: any) => {
      switch (event) {
        case WebSocketEventType.BodyChange:
          setPost((prevPost) => ({
            ...prevPost,
            content: {
              ...prevPost.content,
              body: data.body,
              json: data.json,
            },
          }));
          break;
        case WebSocketEventType.EditorInUse:
          if (
            userEditing !== null &&
            userEditing !== user?._id &&
            data?.userId === user?._id
          ) {
            setSuccessMessage('You are now the editor of the post!');
          }
          setUserEditing(data?.userId);
          break;
        case WebSocketEventType.ChatMessage:
          setPost((prevPost) => ({
            ...prevPost,
            chat: [...(prevPost.chat || []), data],
            unreadMessagesCount: (prevPost.unreadMessagesCount || 0) + 1,
          }));
          break;
        case WebSocketEventType.UpdateMedia:
          setPost((prevPost) => ({
            ...prevPost,
            content: {
              ...prevPost.content,
              media: data.media,
            },
          }));
          initialPostMediaRef.current = (data.media || [])
            .map((media: PostContentMedia) => media.url)
            .join(',');
          break;
        case WebSocketEventType.UpdateTags:
          setPost((prevPost) => ({
            ...prevPost,
            tags: data.tags,
          }));
          break;
        case WebSocketEventType.UpdateLifecycleState:
          setPost((prevPost) => ({
            ...prevPost,
            lifecycleState: data.lifecycleState,
            lifecycleStateDisplay: data.lifecycleStateDisplay,
            schedule: data.schedule,
            postedAt: data.postedAt,
            linkedin: data.linkedin,
          }));
          break;
        case WebSocketEventType.UpdateThumbnail:
          setPost((prevPost) => ({
            ...prevPost,
            content: {
              ...prevPost.content,
              thumbnail: data.thumbnail,
            },
          }));
          initialPostThumbnailRef.current = data.thumbnail;
          break;
        case WebSocketEventType.ShowThumbnail:
          setShowThumbnail(data.showThumbnail);
          break;
        case WebSocketEventType.ChangeIdea:
          setPost((prevPost) => ({
            ...prevPost,
            isIdea: data.isIdea,
          }));
          break;
        case WebSocketEventType.ChangeTitle:
          setPost((prevPost) => ({
            ...prevPost,
            title: data.title,
          }));
          break;

        case WebSocketEventType.SaveAcknowledgment:
          setIsSaved(true);
          break;
        default:
          break;
      }
    },
    [setSuccessMessage, user?._id, userEditing],
  );

  useEffect(() => {
    if (postId) getPost({ variables: { postId } });
  }, [getPost, postId]);

  useEffect(() => {
    if (socket) {
      socket.emit(WebSocketEventType.Sync, { postId: postId });
    }
  }, [socket, postId]);

  useEffect(() => {
    // Set up the event listener for socket messages
    socket?.onAny(handleSocketMessages);

    // Clean up the event listener when the component unmounts or dependencies change
    return () => {
      socket?.offAny(handleSocketMessages);
    };
  }, [socket, handleSocketMessages]);

  const {
    postOnLinkedin,
    isTokenAboutToExpire,
    error: errorLinkedin,
    deleteLinkedinPost: unpublishLinkedinPost,
  } = useLinkedin();
  const [error, setError] = useState<{ code: StatusCode; message?: string } | null>(null);
  const [updatePostSchedule] = useMutation(UPDATE_SCHEDULE_POST);
  const [updateTags] = useMutation(UPDATE_TAGS);
  const [updateState] = useMutation(UPDATE_POST_STATE);
  const [updateDate] = useMutation(UPDATE_POST_DATE);
  const [createPost, { loading: creatingPost }] = useMutation(CREATE_POST);
  const [updateContentPost, { loading: savingPostContent }] =
    useMutation(UPDATE_CONTENT_POST);
  const [createAdvocacyPost, { loading: creatingAdvocacyPost }] =
    useMutation(CREATE_ADVOCACY_POST);
  const [updateAdvocacyPost, { loading: savingAdvocacyPost }] =
    useMutation(UPDATE_ADVOCACY_POST);
  const [deleteAdvocacyPost] = useMutation(DELETE_ADVOCACY_POST);
  const [changePostTitle] = useMutation(CHANGE_POST_TITLE);
  const [selectIsIdeaPost] = useMutation(SELECT_IS_IDEA_POST);
  const [hardDeletePost, { loading: deletingPost }] = useMutation(HARD_DELETE_POST);

  const isPostLoaded = postId ? !!post._id : true;
  const isNew = !post._id;
  const isPosted = post?.lifecycleState === PostLifecycleState.Posted;
  const charsCount =
    postRef?.current?.content?.body?.replace(/<[^>]*>?/gm, '').length ||
    0 + (postRef?.current?.content?.body?.match(/<br\s*\/?>/gi)?.length || 0);

  const trialEnded = !!user?.activeTrial && new Date(user?.trialEnd) < new Date();
  const isScheduled = post.lifecycleState === PostLifecycleState.Scheduled;
  const isNotCurrentUserPost = post.brand?.userId !== user?._id;
  const isCurrentUserEditing = userEditing === user?._id || userEditing === anonymousId;
  const isAdvocacyParent = post.type === PostType.AdvocacyParent;

  const isAdvocacy = post.type === PostType.Advocacy;
  const isAdvocacyEditable = isAdvocacy && !!post.advocacy?.editable;
  const isEditDisabled =
    isPosted ||
    isPosting ||
    (isAdvocacy && !isAdvocacyEditable) ||
    trialEnded ||
    !isCurrentUserEditing;

  const isSubmitDisabled =
    (isPosting ||
      creatingPost ||
      !!!user?.isActive ||
      (post.type !== PostType.AdvocacyParent && !post.brand?.account) ||
      (post.type !== PostType.AdvocacyParent &&
        isNotCurrentUserPost &&
        !user.role.includes(UserRole.PostForOthers)) ||
      !post.lifecycleStateDisplay?.isPostPostable ||
      !isCurrentUserEditing) &&
    (!isLink || (isLink && isEditDisabled && !isCurrentUserEditing)) &&
    !isLinkInbox;

  const isEditable = (isAdvocacy && isAdvocacyEditable) || !isAdvocacy;

  const isSaving =
    creatingPost ||
    savingPostContent ||
    savingAdvocacyPost ||
    creatingAdvocacyPost ||
    deletingPost;

  const isSavable =
    isNew ||
    (!isPosting &&
      !creatingPost &&
      (!!user?.isActive || (!user && !!post.shareLink?.share)) &&
      !isSaving &&
      isEditable);

  const handleBodyChange = (html: string, json: any, action?: Tag) => {
    if (!isNew && socket && socket.connected) {
      socket.emit(WebSocketEventType.BodyChange, {
        postId: post._id,
        body: html,
        json: json,
        action: action,
      });

      setIsSaved(false);
    }

    postRef.current.content = { body: html, json };

    setPost((post) => {
      const tags = [...(post.tags || [])];
      // if (action && !tags.includes(action)) tags.push(action);
      if (action && !tags.some((tag) => tag?.name === action?.name)) tags.push(action);

      return {
        ...post,
        content: {
          ...post.content,
          body: html,
          json: json,
        },
        tags,
      };
    });
  };

  const handleCloseConfirmationPostsDialog = () => {
    setConfirmationPostsDialogOpen(false);
  };

  const handleClosePost = () => {
    if (postRef.current) {
      postRef.current.content = { body: '', json: null };
      postRef.current.title = '';
    }
  };

  const handleAddHelper = (helperText: string, type: PostHelperType) => {
    // Get the current content from postRef
    const currentContent = postRef.current?.content?.body || '';

    // Parse the current content to find the first <p class="tiptap-p"> tag
    const parser = new DOMParser();
    const doc = parser.parseFromString(currentContent, 'text/html');

    // Find the first <p class="tiptap-p">
    const targetParagraph = doc.querySelector('p.tiptap-p');

    if (targetParagraph) {
      // Insert the helper text into the paragraph based on the type
      if (type === PostHelperType.Hooks) {
        targetParagraph.innerHTML = `${helperText}<br>${targetParagraph.innerHTML}`; // Add helper at the beginning with a break
      } else {
        targetParagraph.innerHTML = `${targetParagraph.innerHTML}<br>${helperText}`; // Add helper at the end with a break
      }

      // Serialize the updated content back to HTML
      const updatedContent = doc.body.innerHTML;

      // Update the postRef's content
      postRef.current.content = {
        ...postRef.current.content,
        body: updatedContent,
      };

      // Sync the change via WebSocket and update the database
      if (!isNew) {
        updateContentPost({
          variables: {
            postId: postRef.current._id,
            html: updatedContent,
            json: postRef.current.content.json, // Assuming JSON stays the same
          },
          onError: () => {
            setError({
              code: StatusCode.SAVE_POST_ERROR,
              message: DefaultErrorMessage.SAVE_POST_ERROR,
            });
          },
        });

        if (socket && socket.connected) {
          socket.emit(WebSocketEventType.BodyChange, {
            postId: postRef.current._id,
            body: updatedContent,
            json: postRef.current.content.json,
          });
        }
      }

      // Update the state for new posts or local updates
      setPost((prevPost) => ({
        ...prevPost,
        content: {
          ...prevPost.content,
          body: updatedContent,
        },
      }));

      setIsSaved(false);
    } else {
      console.error('No <p class="tiptap-p"> found in the content.');
    }
  };

  const handleOpenConfirmationPostsDialog = () => {
    setConfirmationPostsDialogOpen(true);
  };

  const handleOpenRequestApprovalDialog = () => {
    setRequestApprovalDialogOpen(true);
  };

  const handleCloseRequestApprovalDialog = () => {
    setRequestApprovalDialogOpen(false);
  };

  const handleUploadMedia = (media: PostContentMedia[]) => {
    const current = (post.content?.media || []).map(({ __typename, ...rest }) => rest);
    const newMedia = media.map(({ __typename, ...rest }) => rest);

    if (!isNew) {
      updateContentPost({
        variables: {
          postId: post?._id!,
          html: postRef?.current?.content?.body || '',
          json: postRef?.current?.content?.json || {},
          media: newMedia,
        },
        onError: () => {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });

          setPost((post) => ({
            ...post,
            content: {
              ...post.content,
              media: current,
            },
          }));

          if (socket && socket.connected) {
            socket.emit(WebSocketEventType.UpdateMedia, {
              postId: post._id,
              media: current,
            });
          }
        },
      });
    }

    setPost((post) => ({
      ...post,
      content: {
        ...post.content,
        media: newMedia,
      },
    }));

    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.UpdateMedia, {
        postId: post._id,
        media: newMedia,
      });
    }
  };

  const handleErrorThumbnail = (value: boolean) => {
    setErrorThumbnail(value);
  };

  const handleLoadingThumbnail = (value: boolean) => {
    setLoadingThumbnail(value);
  };

  const handleShowErrorThumbnail = (value: boolean) => {
    setShowErrorThumbnail(value);
  };

  const handleUploadThumbnail = async (thumbnail: Thumbnail) => {
    if (!!post.content?.media?.length) return;

    const current = post.content?.thumbnail;
    const newThumbnail = thumbnail;

    if (!isNew) {
      updateContentPost({
        variables: {
          postId: post?._id!,
          thumbnail: newThumbnail,
        },
        onError: () => {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });

          setPost((post) => ({
            ...post,
            content: {
              ...post.content,
              thumbnail: current,
            },
          }));

          if (socket && socket.connected) {
            socket.emit(WebSocketEventType.UpdateThumbnail, {
              postId: post._id,
              media: current,
            });
          }
        },
      });
    }

    setPost((post) => ({
      ...post,
      content: {
        ...post.content,
        thumbnail: newThumbnail,
      },
    }));

    setShowThumbnail(true);
    setErrorThumbnail(false);
    setLoadingThumbnail(false);

    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.UpdateThumbnail, {
        postId: post._id,
        thumbnail: newThumbnail,
      });

      socket.emit(WebSocketEventType.ShowThumbnail, {
        postId: post._id,
        showThumbnail: true,
      });
    }
  };

  const handleDeleteMedia = (mediaIndex: number) => {
    const current = (post.content?.media || []).map(({ __typename, ...rest }) => rest);
    const newMedia = post.content?.media
      ?.filter((_, index) => index !== mediaIndex)
      .map(({ __typename, ...media }) => media);

    if (!isNew) {
      updateContentPost({
        variables: {
          postId: post?._id!,
          html: postRef?.current?.content?.body || '',
          json: postRef?.current?.content?.json || {},
          media: newMedia,
        },
        onError: () => {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });

          setPost((post) => ({
            ...post,
            content: {
              ...post.content,
              media: current,
            },
          }));

          if (socket && socket.connected) {
            socket.emit(WebSocketEventType.UpdateMedia, {
              postId: post._id,
              media: current,
            });
          }
        },
      });
    }

    setPost({
      ...post,
      content: {
        ...post.content,
        media: newMedia,
      },
    });

    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.UpdateMedia, {
        postId: post._id,
        media: newMedia,
      });
    }
  };

  const handleSaveAsDraft = ({
    callback,
    lifecycleState,
    schedule,
  }: {
    callback?: (post: Post) => void;
    lifecycleState?: PostLifecycleState;
    schedule?: Date;
  }) => {
    const isInsight = post.tags?.some((tag) => tag?.name === 'FROM_INSIGHT');
    const body = isInsight ? post.content?.body : postRef?.current?.content?.body || '';
    const json = isInsight ? post.content?.json : postRef?.current?.content?.json || {};
    if (isNew && !isSaving && !isPosting) {
      createPost({
        variables: {
          input: {
            brandId: post.brandId!,
            brandName: post.brand?.name || '',
            shellId: post.brand?.shellId || '',
            shellName: post.brand?.shell?.name || '',
            lifecycleState:
              lifecycleState || post.lifecycleState || PostLifecycleState.Ready,
            type: PostType.ReadyToShare,
            content: {
              body: body,
              json: json,
              media: post.content?.media?.map((media) => ({
                url: media?.url,
                type: media?.type,
                storagePath: media?.storagePath,
                alt: media?.alt,
              })),
              thumbnail:
                showThumbnail && !post.content?.media?.length
                  ? post.content?.thumbnail
                  : null,
            },
            schedule: schedule || post.schedule,
            magnets: post.magnets || 0,
            title: post.title,
            isIdea: post.isIdea || false,
            tags: post.tags?.map((tag) => {
              return {
                _id: tag?._id,
                name: tag?.name,
                color: tag?.color,
                internal: tag?.internal || false,
              } as Tag;
            }),
          },
        },
        onCompleted: (data) => {
          if (!data.createPost.success) {
            setError({
              code: StatusCode.SAVE_POST_ERROR,
              message: DefaultErrorMessage.SAVE_POST_ERROR,
            });
            return;
          }

          if (data.createPost?.post?._id) {
            const _id = data.createPost.post?._id;
            setPost((post) => ({ ...post, _id }));
          }

          if (postRef.current.content && data.createPost.post?.content) {
            postRef.current.content.body = data.createPost.post.content.body;
            postRef.current.content.json = data.createPost.post.content.json;
          }

          initialPostBodyRef.current = data?.createPost.post?.content?.body!;
          initialPostMediaRef.current = (data?.createPost.post?.content?.media || [])

            .map((media) => media.url)
            .join(',');
          if (lifecycleState !== PostLifecycleState.PendingApproval) {
            setSuccessMessage('All set! Your post has been saved. 💾');
          }

          if (callback) {
            callback(data.createPost.post as Post);
          }
        },
        onError: () => {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });
        },
      });

      return;
    }

    const newMedia = post?.content?.media?.map((media) => ({
      url: media?.url,
      type: media?.type,
      storagePath: media?.storagePath,
      alt: media?.alt,
    }));

    const thumbnail: Thumbnail = {
      description: post.content?.thumbnail?.description,
      title: post.content?.thumbnail?.title,
      source: post.content?.thumbnail?.source,
      media: {
        url: post.content?.thumbnail?.media?.url,
        type: post.content?.thumbnail?.media?.type,
        storagePath: post.content?.thumbnail?.media?.storagePath,
        alt: post.content?.thumbnail?.media?.alt,
      },
    };

    updateContentPost({
      variables: {
        postId: post?._id!,
        html: postRef?.current?.content?.body || '',
        json: postRef?.current?.content?.json || {},
        media: newMedia,
        thumbnail:
          showThumbnail && !newMedia?.length && post.content?.thumbnail?.source
            ? thumbnail
            : null,
      },
      update: (cache, { data }) => {
        initialPostBodyRef.current = data?.updateContentPost.post?.content?.body!;
        initialPostMediaRef.current = (data?.updateContentPost.post?.content?.media || [])
          .map((media) => media.url)
          .join(',');
      },
      onError: (error) => {
        console.error(error);
      },
    })
      .then(({ data }) => {
        if (callback) {
          callback({ ...post, content: { ...data?.updateContentPost } } as Post);
        }
      })
      .catch(() => {
        setError({
          code: StatusCode.SAVE_POST_ERROR,
          message: DefaultErrorMessage.SAVE_POST_ERROR,
        });
      });
  };

  const handleDuplicate = (callback: (post: Post) => void = () => {}) => {
    createPost({
      variables: {
        input: {
          brandId: post.brandId!,
          brandName: post.brand?.name || '',
          shellId: post.shellId || '',
          shellName: post.shellName || '',
          lifecycleState: PostLifecycleState.Draft,
          type: PostType.ReadyToShare,
          title: post.title,
          content: {
            body: postRef?.current?.content?.body || '',
            json: postRef?.current?.content?.json || {},
            media: post.content?.media?.map((media) => ({
              url: media?.url,
              type: media?.type,
              storagePath: media?.storagePath,
              alt: media?.alt,
            })),
            thumbnail:
              showThumbnail && !post.content?.media?.length
                ? post.content?.thumbnail
                : null,
          },
          magnets: post.magnets || 0,
        },
      },
      onCompleted: (data) => {
        if (!data.createPost.success) {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });
          return;
        }

        if (data.createPost?.post) {
          setPost(data.createPost.post);
        }

        initialPostBodyRef.current = data?.createPost.post?.content?.body!;
        initialPostMediaRef.current = (data?.createPost.post?.content?.media || [])
          .map((media) => media.url)
          .join(',');

        setSuccessMessage('Your post has been duplicated.');

        if (callback) {
          callback(data.createPost.post as Post);
        }
      },
      onError: () => {
        setError({
          code: StatusCode.SAVE_POST_ERROR,
          message: DefaultErrorMessage.SAVE_POST_ERROR,
        });
      },
    });
  };

  const handleCreateAdvocacy = (callback = () => {}) => {
    createAdvocacyPost({
      variables: {
        input: {
          ...advocacyPostInput,
          recipients: advocacyPostInput?.recipients || [],
          content: {
            body: postRef?.current?.content?.body || '',
            json: postRef?.current?.content?.json || {},
            media: post.content?.media?.map((media) => ({
              url: media?.url,
              type: media?.type,
              storagePath: media?.storagePath,
              alt: media?.alt,
            })),
            thumbnail:
              showThumbnail &&
              !post.content?.media?.length &&
              !!post.content?.thumbnail?.source
                ? {
                    source: post.content?.thumbnail?.source,
                    description: post.content?.thumbnail?.description,
                    title: post.content?.thumbnail?.title,
                    media: {
                      url: post.content?.thumbnail?.media?.url,
                      type: post.content?.thumbnail?.media?.type,
                      storagePath: post.content?.thumbnail?.media?.storagePath,
                      alt: post.content?.thumbnail?.media?.alt,
                    },
                  }
                : null,
          },
          shellId: user?.shellId!,
          shellName: user?.shellName!,
          tags: post.tags?.map((tag) => {
            return {
              _id: tag?._id,
              name: tag?.name,
              color: tag?.color,
              internal: tag?.internal || false,
            } as Tag;
          }),
        },
      },
      onCompleted: (data) => {
        callback();
      },
      onError: (err) => {
        callback();
      },
      refetchQueries: [GET_ADVOCACY_PARENT_POSTS],
    });
  };

  const handleUpdateAdvocacy = (callback = () => {}) => {
    updateAdvocacyPost({
      variables: {
        postId: post?._id!,
        input: {
          ...advocacyPostInput,
          shellId: user?.shellId!,
          shellName: user?.shellName!,
          recipients:
            advocacyPostInput?.recipients.map((recipient) => ({
              ...recipient,
              notifications: recipient.notifications,
            })) || [],
          content: {
            body: postRef?.current?.content?.body || '',
            json: postRef?.current?.content?.json || {},
            media: post.content?.media?.map((media) => ({
              url: media?.url,
              type: media?.type,
              storagePath: media?.storagePath,
              alt: media?.alt,
            })),
            thumbnail:
              showThumbnail &&
              !post.content?.media?.length &&
              !!post.content?.thumbnail?.source
                ? {
                    source: post.content?.thumbnail?.source,
                    description: post.content?.thumbnail?.description,
                    title: post.content?.thumbnail?.title,
                    media: {
                      url: post.content?.thumbnail?.media?.url,
                      type: post.content?.thumbnail?.media?.type,
                      storagePath: post.content?.thumbnail?.media?.storagePath,
                      alt: post.content?.thumbnail?.media?.alt,
                    },
                  }
                : null,
          },
        },
      },
      onCompleted: (data) => {
        callback();
      },
      onError: (err) => {
        callback();
      },
      refetchQueries: [GET_ADVOCACY_PARENT_POSTS, GET_CURRENT_BRAND_POSTS],
    });
  };

  const handleHideThumbnail = () => {
    const current = post.content?.thumbnail;
    const newThumbnail = null;

    if (!isNew) {
      updateContentPost({
        variables: {
          postId: post?._id!,
          thumbnail: newThumbnail,
        },
        onError: () => {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });

          setPost((post) => ({
            ...post,
            content: {
              ...post.content,
              thumbnail: current,
            },
          }));
        },
      });
    }

    setShowThumbnail(false);
    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.ShowThumbnail, {
        postId: post._id,
        showThumbnail: false,
      });
    }
  };

  const handleShowThumbnail = () => {
    if (showThumbnail) return;

    if (!isNew) {
      updateContentPost({
        variables: {
          postId: post?._id!,
          thumbnail: post.content?.thumbnail,
        },
        onError: () => {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });

          setPost((post) => ({
            ...post,
            content: {
              ...post.content,
              thumbnail: post.content?.thumbnail,
            },
          }));
        },
      });
    }

    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.ShowThumbnail, {
        postId: post._id,
        showThumbnail: true,
      });
    }

    setShowThumbnail(true);
  };

  const handleDateChange = (date: Date | null, callback = () => {}) => {
    const currentDate = post.schedule;

    setPost({
      ...post,
      schedule: date,
    });

    if (!isNew) {
      updateDate({
        variables: {
          postId: post._id,
          date: date ? new Date(date) : date,
        },
        onCompleted: ({ updatePostDate: response }) => {
          if (response.success) {
            if (socket && socket.connected) {
              socket.emit(WebSocketEventType.UpdateLifecycleState, {
                postId: postId,
                lifecycleState: post.lifecycleState,
                lifecycleStateDisplay: post.lifecycleStateDisplay,
                schedule: response.post?.schedule,
              });
            }
          } else {
            setPost({
              ...post,
              schedule: currentDate,
            });

            setError({
              code: StatusCode.SAVE_POST_ERROR,
              message: DefaultErrorMessage.SAVE_POST_ERROR,
            });
          }
        },
      });

      return;
    }
  };

  const handleScheduleChange = (date: Date | null, callback = () => {}) => {
    if (isTokenAboutToExpire(post.brand?.account)) {
      setError({
        code: StatusCode.LINKEDIN_TOKEN_ABOUT_TO_EXPIRE,
        message: DefaultErrorMessage.LINKEDIN_TOKEN_ABOUT_TO_EXPIRE,
      });
      return;
    }

    if (isNew) {
      handleSaveAsDraft({
        callback,
        lifecycleState: date ? PostLifecycleState.Scheduled : PostLifecycleState.Ready,
        schedule: date || undefined,
      });
    } else {
      updateSchedule(date, callback);
    }
  };

  const handleRemoveSchedule = (callback = () => {}) => {
    if (!!!post?._id) {
      setPost({
        ...post,
        lifecycleState: PostLifecycleState.Ready,
        schedule: null,
      });
      return;
    }

    updateSchedule(null, callback);
  };

  const updateSchedule = (date: Date | null, callback = () => {}) => {
    updatePostSchedule({
      variables: {
        postId: post?._id!,
        schedule: date,
      },
      onCompleted: (data) => {
        const { success, message } = data?.updateSchedulePost || {};
        if (!success) throw new Error(message || 'Schedule could not be updated');
        if (socket && socket.connected) {
          socket.emit(WebSocketEventType.UpdateLifecycleState, {
            postId: postId,
            lifecycleState: data.updateSchedulePost.post?.lifecycleState,
            lifecycleStateDisplay: data.updateSchedulePost.post?.lifecycleStateDisplay,
            schedule: data.updateSchedulePost.post?.schedule,
          });
        }
        setPost({ ...post, ...data.updateSchedulePost.post });
        callback();
      },
      onError: (err) => {
        setError({ code: StatusCode.SCHEDULE_POST_ERROR, message: err.message });
      },
    });
  };

  const onPostOnLinkedinCompleted = (postUrn: string, postId: string) => {
    setIsPosting(false);
    handleCloseConfirmationPostsDialog();
    const lifecycleStateDisplay = {
      name: 'Posted',
      color: '#7FBEBE',
      isStateChangeable: false,
      isPostPostable: false,
    };

    const postUpdates = {
      lifecycleState: PostLifecycleState.Posted,
      lifecycleStateDisplay,
      postedAt: new Date(),
      linkedin: {
        ...post?.linkedin,
        postUrn,
      },
    };

    setPost({
      ...post,
      ...postUpdates,
      _id: postId,
    });
    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.UpdateLifecycleState, {
        postId: postId,
        ...postUpdates,
      });
    }

    client.refetchQueries({ include: [GET_CURRENT_BRAND_POSTS] });
    setSuccessMessage('Post created successfully 🎉');
  };

  const onUnpublishedPostCompleted = () => {
    setIsUnposting(false);
    setPost({
      ...post,
      lifecycleState: PostLifecycleState.Ready,
      lifecycleStateDisplay: {
        name: 'Ready',
        color: '#E0C8FF',
        isStateChangeable: true,
        isPostPostable: true,
      },
    });

    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.UpdateLifecycleState, {
        postId: postId,
        lifecycleState: PostLifecycleState.Ready,
        lifecycleStateDisplay: {
          name: 'Ready',
          color: '#E0C8FF',
          isStateChangeable: true,
          isPostPostable: true,
        },
      });
    }

    setSuccessMessage('Post has been successfully deleted.');
    client.refetchQueries({ include: [GET_CURRENT_BRAND_POSTS] });
  };

  const onPostOnLinkedinError = () => {
    setPost({
      ...post,
      lifecycleState: PostLifecycleState.Error,
      lifecycleStateDisplay: {
        name: 'Error',
        color: '#FF7A81',
        order: 8,
        isStateChangeable: false,
        isPostPostable: true,
      },
    });

    setIsPosting(false);
    setIsUnposting(false);
  };

  const handlePostOnLinkedin = async (callback?: () => void) => {
    setError(null);
    const account = post.brand?.account;

    if (!account) {
      setError({
        code: StatusCode.LINKEDIN_TOKEN_NOT_FOUND,
        message: DefaultErrorMessage.LINKEDIN_TOKEN_NOT_FOUND,
      });
      return;
    }

    setIsPosting(true);

    setPost({
      ...post,
      lifecycleState: PostLifecycleState.Posting,
      lifecycleStateDisplay: getLifecycleStateDisplay(PostLifecycleState.Posting),
    });

    const onCompleted = (postUrn: string, postId: string) => {
      onPostOnLinkedinCompleted(postUrn, postId);
      if (callback) callback();
    };

    if (isNew) {
      handleSaveAsDraft({
        callback: (savedPost) => {
          postOnLinkedin(savedPost, account, onCompleted, onPostOnLinkedinError);
        },
      });
      return;
    }

    postOnLinkedin(post, account, onCompleted, onPostOnLinkedinError);
  };

  const handleUnpublishLinkedinPost = () => {
    const account = post.brand?.account;

    if (!account) {
      setError({
        code: StatusCode.LINKEDIN_TOKEN_NOT_FOUND,
        message: DefaultErrorMessage.LINKEDIN_TOKEN_NOT_FOUND,
      });
      return;
    }

    setIsUnposting(true);
    unpublishLinkedinPost(
      post,
      account,
      onUnpublishedPostCompleted,
      onPostOnLinkedinError,
    );
  };

  const handleDeleteAdvocacy = (callback: () => void) => {
    deleteAdvocacyPost({
      variables: {
        postId: post?._id!,
      },
      onCompleted: callback,
      onError: (error) => {
        setError({
          code: StatusCode.DELETE_POST_ERROR,
          message: `${DefaultErrorMessage.DELETE_POST_ERROR}: ${error.message}`,
        });
      },
      update: (cache) => {
        const normalizedId = cache.identify({ _id: post?._id, __typename: 'Post' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const handleRecipientsChange = (recipients: Brand[]) => {
    setAdvocacyPostInput({
      ...advocacyPostInput,
      recipients: recipients.map((recipient) => ({
        brandId: recipient._id!,
        brandName: recipient?.name || '',
        ...(recipient.user && {
          userId: recipient.userId,
          userFirstName: recipient.user?.firstName,
          userFullName: getFullName(recipient.user),
          language: recipient.user?.language,
        }),
        email: recipient.user?.email,
        notifications: recipient.user?.notifications?.email,
      })),
    });
  };

  const handleToggleAdvocacyEditable = () => {
    setAdvocacyPostInput({
      ...advocacyPostInput,
      editable: !advocacyPostInput?.editable,
    });
  };

  const handleMagnetsChange = (magnets: number) => {
    setAdvocacyPostInput({
      ...advocacyPostInput,
      magnets,
    });
  };

  const handleTurnIntoAdvocacy = () => {
    setPost({
      ...post,
      type: PostType.AdvocacyParent,
    });
  };

  const handleAddTags = (tags: Tag[]) => {
    const oldTags = post.tags || [];

    const newTags = tags.map((tag) => ({
      _id: tag._id,
      name: tag.name,
      color: tag.color,
      internal: tag.internal || false,
    }));

    // Actualizamos el estado local inmediatamente
    setPost({
      ...post,
      tags: [...oldTags, ...newTags],
    });

    // Si el post tiene un ID, actualizamos la base de datos
    if (post?._id) {
      updateTags({
        variables: {
          postId: post._id,
          tags: newTags, // Usamos newTags directamente
        },
        onCompleted: (data) => {
          if (!data.updateTags.success) {
            // Revertimos al estado anterior si la actualización falla
            setPost({
              ...post,
              tags: oldTags,
            });
            setError({
              code: StatusCode.SAVE_POST_ERROR,
              message: DefaultErrorMessage.SAVE_POST_ERROR,
            });
          }
        },
      });

      // Emitimos la actualización por WebSocket
      if (socket && socket.connected) {
        socket.emit(WebSocketEventType.UpdateTags, {
          postId: postId,
          tags: newTags,
        });
      }

      return;
    }

    // Si el post no tiene un ID, simplemente actualizamos el estado local
    setPost({
      ...post,
      tags: newTags,
    });
  };

  const handleRemoveTag = (tag: Tag) => {
    if (post?._id) {
      const tags = post.tags
        ?.filter((t) => t?._id !== tag._id)
        .map((tag) => ({
          _id: tag?._id,
          name: tag?.name,
          color: tag?.color,
          internal: tag?.internal || false,
        }));

      updateTags({
        variables: {
          postId: post._id,
          tags: tags,
        },
      });

      if (socket && socket.connected) {
        socket.emit(WebSocketEventType.UpdateTags, {
          postId: postId,
          tags,
        });
      }
    }

    setPost({
      ...post,
      tags: post.tags?.filter((t) => t?._id !== tag._id),
    });
  };

  const handleUnreadMessagesChange = (unreadMessagesCount: number) => {
    if (unreadMessagesCount === 0) {
      setPost({
        ...post,
        unreadMessagesCount,
      });

      return;
    }

    if (unreadMessagesCount > 0) {
      setPost((prevPost) => ({
        ...prevPost,
        unreadMessagesCount: (prevPost.unreadMessagesCount || 0) + unreadMessagesCount,
      }));
    }
  };

  const handleSendChatMessage = (text: string) => {
    const trimmedText = text.replace(/^\s+|\s+$/g, ''); // Trim leading/trailing whitespace

    if (trimmedText) {
      const message: MessagesTeam = {
        content: trimmedText,
        role: RoleChat.User,
        time: new Date(),
        readBy: isLinkInbox
          ? [post.brand?.userId!]
          : user
          ? [user._id]
          : [post.brand?.userId!], // Set readBy to the brand owner in Linked Inbox
        name: post.brandName, // Always use brand name for Linked Inbox
        userOrBrand: isLinkInbox ? post.brand : user || post.brand, // Always use the brand as sender in Linked Inbox
        userId: isLinkInbox ? post.brand?.userId! : user?._id || post.brand?.userId, // Set userId to brand owner in Linked Inbox
      };

      setPost({
        ...post,
        chat: [...(post.chat || []), message], // Add the new message to the chat
      });

      if (socket && !isNew) {
        socket.emit(WebSocketEventType.ChatMessage, {
          postId: postId,
          avatar: isLinkInbox ? post.brand?.account?.avatar : user?.avatar, // Use brand avatar for Linked Inbox
          ...message,
        });
      }
    }
  };

  const handleStateChange = (lifecycleState: PostLifecycleState) => {
    const lifecycleStateDisplay = getLifecycleStateDisplay(lifecycleState);
    const currentState = post.lifecycleState;
    const currentStateDisplay = post.lifecycleStateDisplay;

    setPost({
      ...post,
      lifecycleState,
      lifecycleStateDisplay,
    });

    if (!isNew) {
      updateState({
        variables: {
          postId: post._id,
          lifecycleState,
        },
        onCompleted: ({ updatePostLifecycleState: response }) => {
          if (response.success) {
            if (socket && socket.connected) {
              socket.emit(WebSocketEventType.UpdateLifecycleState, {
                postId: postId,
                lifecycleState: response.post.lifecycleState,
                lifecycleStateDisplay: response.post.lifecycleStateDisplay,
              });
            }
          } else {
            setPost({
              ...post,
              lifecycleState: currentState,
              lifecycleStateDisplay: currentStateDisplay,
            });
            setError({
              code: StatusCode.SAVE_POST_ERROR,
              message: DefaultErrorMessage.SAVE_POST_ERROR,
            });
          }
        },
      });
    }

    if (lifecycleState === PostLifecycleState.PendingApproval) {
      handleOpenRequestApprovalDialog();
    }
  };

  const handleDeletePost = (callback?: () => void) => {
    hardDeletePost({
      variables: {
        postId: post._id,
      },
      onCompleted: () => {
        setSuccessMessage('Post deleted successfully');
        if (callback) callback();
      },
    });
  };

  const handleTitleChange = (title: string) => {
    if (!isNew) {
      changePostTitle({
        variables: {
          postId: post._id,
          title,
        },
        onCompleted: (data) => {
          if (!data.changePostTitle?.success) {
            setError({
              code: StatusCode.SAVE_POST_ERROR,
              message: DefaultErrorMessage.SAVE_POST_ERROR,
            });
            return;
          }
        },
        onError: (error) => {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });
        },
      });
    }

    setPost({
      ...post,
      title,
    });

    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.ChangeTitle, {
        postId: postId,
        title,
      });
    }
  };

  const handlePostIdea = (isIdea: boolean) => {
    if (!isNew) {
      selectIsIdeaPost({
        variables: {
          postId: post._id,
          isIdea,
        },
        onCompleted: (data) => {
          if (!data.selectIsIdeaPost?.success) {
            setError({
              code: StatusCode.SAVE_POST_ERROR,
              message: DefaultErrorMessage.SAVE_POST_ERROR,
            });
            return;
          }
        },
        onError: (error) => {
          setError({
            code: StatusCode.SAVE_POST_ERROR,
            message: DefaultErrorMessage.SAVE_POST_ERROR,
          });
        },
      });
    }

    if (socket && socket.connected) {
      socket.emit(WebSocketEventType.ChangeIdea, {
        postId: postId,
        isIdea,
      });
    }

    setPost({
      ...post,
      isIdea,
    });
  };

  return {
    post,
    advocacyPostInput,
    status: {
      isPostLoaded,
      isNew,
      isPosted,
      // isValid,
      isSaving,
      isSubmitDisabled,
      isEditDisabled,
      isPosting,
      isUnposting,
      isScheduled,
      isAdvocacy,
      isAdvocacyParent,
      isAdvocacyEditable,
      isSavable,
      charsCount,
      userEditing,
      isCurrentUserEditing,
      isSaved,
      confirmationPostsDialogOpen,
      requestApprovalDialogOpen,
      showThumbnail,
      thumbnailData,
      loadingThumbnail,
      errorThumbnail,
      showErrorThumbnail,
      initialPostBodyRef,
    },
    handlers: {
      handleBodyChange,
      handleUploadMedia,
      handleUploadThumbnail,
      handleDeleteMedia,
      handleSaveAsDraft,
      handleDuplicate,
      handleScheduleChange,
      handleRemoveSchedule,
      handlePostOnLinkedin,
      handleDeleteAdvocacy,
      handleCreateAdvocacy,
      handleUpdateAdvocacy,
      handleRecipientsChange,
      handleToggleAdvocacyEditable,
      handleMagnetsChange,
      handleTurnIntoAdvocacy,
      handleAddTags,
      handleRemoveTag,
      handleUnreadMessagesChange,
      handleSendChatMessage,
      handleStateChange,
      handleDateChange,
      handleUnpublishLinkedinPost,
      handleCloseConfirmationPostsDialog,
      handleOpenConfirmationPostsDialog,
      handleHideThumbnail,
      handleShowThumbnail,
      handleErrorThumbnail,
      handleLoadingThumbnail,
      handleTitleChange,
      handlePostIdea,
      handleDeletePost,
      handleOpenRequestApprovalDialog,
      handleCloseRequestApprovalDialog,
      handleShowErrorThumbnail,
      handleClosePost,
      handleAddHelper,
    },
    error: error || errorLinkedin,
    postRef: postRef,
  };
};

export default usePost;
