import { useQuery } from '@apollo/client';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import {
  BrandMetricsWithShell,
  UserRole,
} from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import { GET_TEAM_PERFORMANCE_METRICS } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';
import BrandsPerformanceComponent from '../../components/Analitycs/BrandsPerformanceComponent';

const BrandsPerformanceScreen = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const init = new Date();
  init.setDate(init.getDate() - 30);
  const end = new Date();

  const [dateInit, setDateInit] = useState<Date>(init);
  const [dateEnd, setDateEnd] = useState<Date>(end);

  const { data: metricsTeam, loading: loadingMetricsTeam } = useQuery(GET_TEAM_PERFORMANCE_METRICS, {
    fetchPolicy: 'network-only',
    variables: {
      shellId: user?.shellId as string,
      dateInit: dateInit,
      dateEnd: dateEnd,
    },
    skip:
      !user ||
      (!user.role.includes(UserRole.BrandManager) &&
        !user.role.includes(UserRole.LimitedBrandManager)),
  });

  const loading = loadingMetricsTeam;

  const changeDateFilter = (dateInitInput: string, dateEndInput: string) => {
    const dateInitISO = new Date(dateInitInput);
    const dateEndISO = new Date(dateEndInput);

    if (dateInit === dateInitISO && dateEnd === dateEndISO) return;

    setDateInit(dateInitISO);
    setDateEnd(dateEndISO);
  };

  return (
    <>
      {loading ? <LinearProgress /> : <Box height={4} />}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        spacing={2}
        padding={4}
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography variant={'h4'} fontWeight="bold">
            {/* {t('Brands Activity')} */}
            Brands Performance
          </Typography>
          <Typography variant={'body1'}>
            {t('Analyze the performance of your team')}
          </Typography>
        </Stack>
      </Stack>
      {/* {metricsData && ( */}
      <Stack direction="column" px={4}>
        <BrandsPerformanceComponent
          dateEnd={dateEnd}
          dateInit={dateInit}
          loading={loading}
          changeDateFilter={changeDateFilter}
          metricsDataTable={metricsTeam?.getTeamPerformanceMetrics?.brandsMetricsWithShell as BrandMetricsWithShell[]}
        />
      </Stack>
      {/* )} */}
    </>
  );
};

export default BrandsPerformanceScreen;
