import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import {
  TeamActivityMetricsResponse,
  BrandMetricsWithShell,
  BrandRankingMagnets,
} from '../../__generated__/graphql';
import { formatPostsCountDifference } from '../../utils';
import CardReport from '../Reports/CardReport';
import AnalitysTable from '../Tables/AnalitysTable';
import { useTranslation } from 'react-i18next';
import DatePickerRange from '../DatePicker/DatePickerRange';

interface Props {
  metricsTeam: TeamActivityMetricsResponse | null;
  metricsDataTable: BrandMetricsWithShell[];
  dateInit: Date;
  dateEnd: Date;
  loading: boolean;
  changeDateFilter: (dateInit: string, dateEnd: string) => void;
}

const BrandsActivityComponent = ({
  metricsTeam,
  dateEnd,
  dateInit,
  loading,
  changeDateFilter,
  metricsDataTable,
}: Props) => {
  const { t } = useTranslation();
  function calculatePercentage(brands: BrandRankingMagnets[]) {
    if (!brands) return [];

    const maxMagnets = () => {
      let max = 0;
      brands.forEach((brand) => {
        if (brand.magnets!! > max) {
          max = brand.magnets!!;
        }
      });
      return max;
    };

    const usersWithPercentage = brands.map((brand) => ({
      name: brand.brandName,
      magnets: brand.magnets,
      percentage: (brand.magnets!! / maxMagnets()) * 100,
    }));

    return usersWithPercentage;
  }
  const metricUsersCount =
    (metricsTeam?.brandsWithPosts30Days || 0) -
    (metricsTeam?.brandsWithPostsPrevious30Days || 0);

  const averageActivity = (data: number) => {
    return data / (metricsTeam?.brandsShellCount || 0) / (30 / 7) || 0;
  };

  const usersWithPercentage = calculatePercentage(
    metricsTeam?.magnetsRanking as BrandRankingMagnets[],
  );

  return (
    <>
      
      <Box
        display="flex"
        gap={2}
        alignContent="center"
        flexDirection={{ xs: 'column', md: 'row' }}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <Box width={{ xs: '100%', sm: '50%', md: '20%' }}>
          <CardReport
            title={t('Active brands')}
            info={t(
              'An active brand is the one that has posted on LinkedIn within the last 30 days.',
            )}
            comparation={`${metricUsersCount > 0 ? '+' : ''} ${metricUsersCount} `}
            lastDays={t('in the last 30 days')}
            logo={false}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {metricsTeam?.brandsWithPosts30Days || 0} /{' '}
              {metricsTeam?.brandsShellCount || 0}
            </Typography>
          </CardReport>
        </Box>
        <Box width={{ xs: '100%', sm: '50%', md: '20%' }}>
          <CardReport
            title={t('Activity')}
            comparation={formatPostsCountDifference(
              metricsTeam?.postsCount30Days || 0,
              metricsTeam?.postsCountPrevious30Days || 0,
            )}
            lastDays={t('vs the previous 30 days')}
            subtitle
            logo={false}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {metricsTeam?.postsCount30Days || 0}
            </Typography>
            <Typography fontSize="1rem" textAlign="center">
              {' '}
              {t('posts')}
            </Typography>
          </CardReport>
        </Box>
        <Box width={{ xs: '100%', sm: '50%', md: '20%' }}>
          <CardReport
            title={t('Average Activity')}
            subtitle
            comparation={
              averageActivity(metricsTeam?.postsCountPrevious30Days || 0) > 0
                ? `+${averageActivity(metricsTeam?.postsCountPrevious30Days || 0).toFixed(
                    2,
                  )}`
                : averageActivity(metricsTeam?.postsCountPrevious30Days || 0).toFixed(2)
            }
            lastDays={t('vs the previous 30 days')}
            logo={false}
          >
            <Typography fontSize="2rem" textAlign="center" mr="5px">
              {(
                (metricsTeam?.postsCount30Days || 0) /
                (metricsTeam?.brandsShellCount || 0) /
                (30 / 7)
              ).toFixed(2) || 'N/A'}
            </Typography>
            <Typography fontSize="1rem" textAlign="center">
              {' '}
              {t('posts per brand-week')}
            </Typography>
          </CardReport>
        </Box>
        <Box width={{ xs: '100%', sm: '80%', md: '40%' }}>
          <CardReport title={t('Magnets’ Ranking')} subtitle comparation=" " logo={false}>
            <Box display="flex" flexDirection="column" gap={1}>
              {usersWithPercentage.map(
                ({ name, magnets, percentage }, index) =>
                  !!magnets && (
                    <Grid container key={index}>
                      <Grid item xs={6}>
                        <Typography fontSize="1rem" fontWeight={index === 0 ? 700 : 500}>
                          {' '}
                          {name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          height="25px"
                          width={`${percentage}%`}
                          style={{ backgroundColor: '#FF007A' }}
                        >
                          <Typography textAlign="end" color="white" paddingRight="1rem">
                            {' '}
                            {magnets}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ),
              )}
            </Box>
          </CardReport>
        </Box>
      </Box>

      <Box marginTop={2} marginBottom={-1}>
        <DatePickerRange
          dateInit={dateInit}
          dateEnd={dateEnd}
          dateFilter={changeDateFilter}
          loadingButton={loading}
        />
      </Box>
      <Box
        sx={{
          marginX: 'auto',
          marginTop: 2,
        }}
      >
        {loading ? <CircularProgress /> : <Box height={4} />}
      </Box>
      {metricsDataTable && <AnalitysTable data={metricsDataTable || []} type='Activity' />}
    </>
  );
};

export default BrandsActivityComponent;
