import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import {
  OrderByDirection,
  Post,
  PostAdvocacyParent,
  PostLifecycleState,
} from '../../__generated__/graphql';
import { DeviceContext } from '../../context/DeviceContext';
import { useTranslation } from 'react-i18next';

const HeaderCell = ({
  text,
  isOrderable = false,
  active = false,
  direction = OrderByDirection.Asc,
  onClick = () => {},
  sx = {},
}: {
  text: string;
  isOrderable?: boolean;
  active?: boolean;
  direction?: OrderByDirection;
  onClick?: () => void;
  sx?: SxProps;
}) => (
  <TableCell sx={{ color: 'primary.main', fontWeight: 'bold', ...sx }}>
    {isOrderable ? (
      <TableSortLabel
        active={active}
        direction={direction === OrderByDirection.Asc ? 'asc' : 'desc'}
        onClick={onClick}
        sx={{ color: 'primary.main', fontWeight: 'bold' }}
      >
        <Typography fontSize="14px" fontWeight="bold">
          {text}
        </Typography>
      </TableSortLabel>
    ) : (
      <Typography fontSize="14px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

const Cell = ({
  text,
  sx,
  children,
}: {
  text?: string;
  sx?: SxProps;
  children?: React.ReactNode;
}) => (
  <TableCell sx={sx}>
    {children || (
      <Typography fontSize="12px" fontWeight="bold">
        {text}
      </Typography>
    )}
  </TableCell>
);

interface Props {
  posts: PostAdvocacyParent[];
  onEditPost: (postId: string) => void;
  onDeletePost: (postId: string, anyPosted: boolean) => void;
}

const AdvocacyPostsTable = ({ posts, onEditPost, onDeletePost }: Props) => {
  const { isMobile } = useContext(DeviceContext);

  const countPostedChildPosts = (parentPost: PostAdvocacyParent) => {
    const isPosted = (psot: Post) => psot.lifecycleState === PostLifecycleState.Posted;

    if (!parentPost.childrenPosts) {
      return 0;
    }

    return parentPost.childrenPosts.reduce(
      (count, posts) => count + (isPosted(posts) ? 1 : 0),
      0,
    );
  };

  const sortedPosts = [...posts].sort((a, b) => {
    if (a.parentPost.createdOn > b.parentPost.createdOn) {
      return -1;
    }
    if (a.parentPost.createdOn < b.parentPost.createdOn) {
      return 1;
    }
    return 0;
  });
  const { t } = useTranslation();

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '20px',
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <HeaderCell text={t('Preview')} />
              <HeaderCell text={t('Created by')} />
              <HeaderCell text={t('Created on')} />
              <HeaderCell text={t('Brands')} />
              <HeaderCell text={t('Posted')} />
              <HeaderCell text={t('Actions')} sx={{ textAlign: 'center' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Sort posts by createdOn date field */}
            {sortedPosts.map((post, index) => {
              const maxChars = isMobile ? 50 : 100;
              let preview = post.parentPost.content?.preview || '';
              if (preview.length > maxChars) {
                preview = preview.slice(0, maxChars) + '...';
              }

              const postedCount = countPostedChildPosts(post);
              return (
                <TableRow
                  key={`${post.parentPost._id} ${index}`} 
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    borderLeft: '5px solid',
                    borderColor: 'primary.main',
                  }}
                  data-testid="post-row"
                >
                  <TableCell sx={{ maxWidth: '20vw' }}>
                    <Box dangerouslySetInnerHTML={{ __html: preview }} />
                  </TableCell>
                  <TableCell>
                    {post.parentPost.createdBy?.firstName}{' '}
                    {post.parentPost.createdBy?.lastName}
                  </TableCell>
                  <Cell
                    text={new Date(post.parentPost.createdOn).toLocaleDateString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      },
                    )}
                  />
                  <Cell>
                    <AvatarGroup
                      max={4}
                      sx={{
                        justifyContent: 'flex-end',
                        '.MuiAvatarGroup-avatar': { backgroundColor: '#BBB' },
                      }}
                    >
                      {post.childrenPosts!.map((child, index) => (
                        <Tooltip title={child.brand?.name} key={`${child.brand?._id} ${index}`}>
                          <Avatar
                            alt={child.brand?.name}
                            src={child.brand?.account?.avatar || ''}
                          />
                        </Tooltip>
                      ))}
                    </AvatarGroup>
                  </Cell>
                  <Cell text={`${postedCount}/${post.childrenPosts?.length}`} />
                  <Cell sx={{ textAlign: 'center' }}>
                    <IconButton
                      onClick={() => onEditPost(post.parentPost._id)}
                      data-testid="edit-post-button"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      disabled={
                        postedCount === post.childrenPosts?.length &&
                        post.childrenPosts?.length > 0
                      }
                      onClick={() => onDeletePost(post.parentPost._id, postedCount > 0)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Cell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdvocacyPostsTable;
