import { Box, CircularProgress } from '@mui/material';
import {
  BrandMetricsWithShell,
} from '../../__generated__/graphql';
import AnalitysTable from '../Tables/AnalitysTable';
import DatePickerRange from '../DatePicker/DatePickerRange';

interface Props {
  metricsDataTable: BrandMetricsWithShell[];
  dateInit: Date;
  dateEnd: Date;
  loading: boolean;
  changeDateFilter: (dateInit: string, dateEnd: string) => void;
}

const BrandsPerformanceComponent = ({
  dateEnd,
  dateInit,
  loading,
  changeDateFilter,
  metricsDataTable,
}: Props) => {

  return (
    <>
      <Box marginTop={2} marginBottom={-1}>
        <DatePickerRange
          dateInit={dateInit}
          dateEnd={dateEnd}
          dateFilter={changeDateFilter}
          loadingButton={loading}
        />
      </Box>
      <Box
        sx={{
          marginX: 'auto',
          marginTop: 2,
        }}
      >
        {loading ? <CircularProgress /> : <Box height={4} />}
      </Box>
      {metricsDataTable && <AnalitysTable data={metricsDataTable || []} type='Performance' />}
    </>
  );
};

export default BrandsPerformanceComponent;
