import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  Dialog,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import {
  FollowersMonthly,
  FollowersMonthlyDetail,
  MetricByUserResponse,
  MetricByWeek,
  MetricsCompanyResponse,
  MonthlyDataEntry,
  PerformingContent,
  Tag,
  TypeBrand,
  UserPlan,
} from '../../__generated__/graphql';
import CompanyPerformance from '../../components/Analitycs/CompanyPerformance';
import IndividualDashboard from '../../components/Analitycs/IndividualDashboard';
import PerformingContentTable from '../../components/Tables/PerformingContentTable';
import { TabPanel } from '../../components/Tabs/TabPanel';
import { AuthContext } from '../../context/AuthContext';
import { GET_METRICS_BY_BRAND, GET_METRICS_COMPANY } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form-mui';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ADD_URLPROFILE_TO_BRAND } from '../../graphql/mutations';
import { LoadingButton } from '@mui/lab';
import { SnackbarContext } from '../../context/SnackbarContext';

const inputStyle = {
  width: '100%',
  maxWidth: '800px',
  height: '40px',
  backgroundColor: 'white',
};

const ANALYTICS_DATA_RECENCY_THRESHOLD = 7; // Days

const Analytics = () => {
  const { t } = useTranslation();
  const init = new Date();
  init.setDate(init.getDate() - 30);
  const end = new Date();

  const { user, refetchCurrentUser } = useContext(AuthContext);
  const brandSelected = user?.brandSelected;
  const [tab, setTab] = useState(0);

  const [dateInit, setDateInit] = useState<Date>(init);
  const [dateEnd, setDateEnd] = useState<Date>(end);
  const [metricsBrand, setMetricsBrand] = useState<PerformingContent[]>([]);
  const [metricsCompany, setMetricsCompany] = useState<MetricsCompanyResponse | null>(
    null,
  );
  const [metricsByWeek, setMetricsByWeek] = useState<MetricByWeek[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const { setSuccessMessage } = useContext(SnackbarContext);

  const differenceInTime = dateEnd.getTime() - dateInit.getTime();

  // Convertir la diferencia de tiempo de milisegundos a días
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

  const updateMetricsBrandWithTags = (tags: Tag[], postId: string) => {
    const updatedMetricsBrand = metricsBrand.map((metric) => {
      if (metric.postId === postId) {
        return { ...metric, tags: tags };
      }
      return metric;
    });

    setMetricsBrand(updatedMetricsBrand);
  };

  const changeDateFilter = (dateInitInput: string, dateEndInput: string) => {
    const dateInitISO = new Date(dateInitInput);
    const dateEndISO = new Date(dateEndInput);

    if (dateInit === dateInitISO && dateEnd === dateEndISO) return;

    setDateInit(dateInitISO);
    setDateEnd(dateEndISO);
  };

  const { data: metricsCompanyResponse, loading: loadingMetricsCompany } = useQuery(
    GET_METRICS_COMPANY,
    {
      fetchPolicy: 'network-only',
      variables: {
        dateInit: dateInit,
        dateEnd: dateEnd,
      },
      skip: brandSelected?.typeBrand === TypeBrand.Personal || !user,
      onCompleted: (data) => {
        setMetricsCompany(data.getMetricsCompany as MetricsCompanyResponse);
      },
    },
  );

  const { data: metricsUser, loading: loadingMetricsUser } = useQuery(
    GET_METRICS_BY_BRAND,
    {
      fetchPolicy: 'network-only',
      variables: {
        brandId: brandSelected?._id!,
        dateInit: dateInit,
        dateEnd: dateEnd,
      },
      skip: !brandSelected || brandSelected?.typeBrand === TypeBrand.Corporate,
      onCompleted: (data) => {
        setMetricsBrand(data.getMetricsByBrand?.performingContent as PerformingContent[]);
        setMetricsByWeek(data.getMetricsByBrand?.metricsByWeek as MetricByWeek[]);
      },
    },
  );

  const [addUrlProfile, { loading: loadingAddUrl }] = useMutation(
    ADD_URLPROFILE_TO_BRAND,
    {
      onCompleted: (data) => {
        if (data.addUrlProfileToBrand?.success) {
          refetchCurrentUser();
          setOpenModal(false);
          setSuccessMessage(t('URL added successfully!'));
        }
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );

  const loading = loadingMetricsUser || loadingMetricsCompany;

  const tabStyle = {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'bold',
    color: 'text.primary',
  };

  const analyticsDataRecentlyUpdated =
    brandSelected?.lastMetricsUpdate &&
    (new Date().getTime() - new Date(brandSelected?.lastMetricsUpdate).getTime()) /
      (1000 * 60 * 60 * 24) <=
      ANALYTICS_DATA_RECENCY_THRESHOLD;

  const tabsConfig = [
    ...(brandSelected?.typeBrand === TypeBrand.Personal
      ? [
          {
            label: t('Content Table'),
            content: (
              <PerformingContentTable
                loadingButton={loading}
                data={metricsBrand}
                dateFilter={changeDateFilter}
                userOrShell="user"
                updateMetricsBrandWithTags={updateMetricsBrandWithTags}
                dateInit={dateInit}
                dateEnd={dateEnd}
              />
            ),
          },
          {
            label: t('Performance'),
            content: (
              <>
                {!brandSelected?.lastMetricsUpdate &&
                  !user?.plan?.includes(UserPlan.Starter) && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                      <strong>
                        {t("Looks like this brand doesn't have any data yet.")}
                      </strong>{' '}
                      {t('Make sure to install our')}{' '}
                      <a
                        href="https://chrome.google.com/webstore/detail/magnett%C3%BC-for-linkedin/gbhgeemkcacibokmfjoimkgohejgehap"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('Chrome Extension')}
                      </a>{' '}
                      {t('to start tracking your performance.')}
                    </Alert>
                  )}

                {brandSelected?.lastMetricsUpdate &&
                  !analyticsDataRecentlyUpdated &&
                  !brandSelected && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      <strong>
                        {t(
                          'It has been more than {{days}} days since we last received your analytics data.',
                          { days: ANALYTICS_DATA_RECENCY_THRESHOLD },
                        )}
                      </strong>{' '}
                      {t('Please check if our')}{' '}
                      <a
                        href="https://chrome.google.com/webstore/detail/magnett%C3%BC-for-linkedin/gbhgeemkcacibokmfjoimkgohejgehap"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('Chrome Extension')}
                      </a>{' '}
                      {t('is correctly installed.')}
                    </Alert>
                  )}
                <IndividualDashboard
                  data={metricsUser?.getMetricsByBrand as MetricByUserResponse}
                  dateFilter={changeDateFilter}
                  dateInit={dateInit}
                  dateEnd={dateEnd}
                  loadingButton={loading}
                  metricsByWeek={metricsByWeek}
                  differenceInDays={differenceInDays}
                  havelastMetricsUpdate={!!brandSelected?.lastMetricsUpdate}
                />
              </>
            ),
          },
        ]
      : []),
    ...(brandSelected?.typeBrand === TypeBrand.Corporate
      ? [
          {
            label: t('Content Table'),
            content: (
              <PerformingContentTable
                loadingButton={loading}
                data={
                  metricsCompanyResponse?.getMetricsCompany
                    ?.performingContent as PerformingContent[]
                }
                dateFilter={changeDateFilter}
                userOrShell="user"
                reactionsRange={
                  metricsCompanyResponse?.getMetricsCompany?.reactionsRange || 0
                }
                commentsRange={
                  metricsCompanyResponse?.getMetricsCompany?.commentsRange || 0
                }
                sharesRange={metricsCompanyResponse?.getMetricsCompany?.sharesRange || 0}
                impressionsRange={
                  metricsCompanyResponse?.getMetricsCompany?.impressionsRange || 0
                }
                dateInit={dateInit}
                dateEnd={dateEnd}
                isBrandCompany
                updateMetricsBrandWithTags={updateMetricsBrandWithTags}
              />
            ),
          },
          {
            label: t('Performance'),
            content: (
              <>
                {!brandSelected?.account ? (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    <strong>
                      {t("It looks like this brand doesn't have an affiliated account.")}
                    </strong>{' '}
                    {t(
                      'Please affiliate an account to enjoy and access all the details of your performance.',
                    )}
                  </Alert>
                ) : (
                  <CompanyPerformance
                    metricsCompany={
                      metricsCompanyResponse?.getMetricsCompany as MetricsCompanyResponse
                    }
                    dateEnd={dateEnd}
                    dateFilter={changeDateFilter}
                    dateInit={dateInit}
                    differenceInDays={differenceInDays}
                    loadingButton={loadingMetricsCompany}
                    followersCurrent={metricsCompany?.followersCurrent || 0}
                    followersMonthly={
                      metricsCompany?.followersMonthly as FollowersMonthly[]
                    }
                    monthlyData={metricsCompany?.monthlyData as MonthlyDataEntry[]}
                    followersMonthlyDetail={
                      metricsCompany?.followersMonthlyDetail as FollowersMonthlyDetail[]
                    }
                  />
                )}
              </>
            ),
          },
        ]
      : []),
  ];

  const schema = yup.object().shape({
    urlProfile: yup.string().required(t('Error Url')),
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      urlProfile: '',
    },
    values: {
      urlProfile: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    addUrlProfile({
      variables: {
        brandId: brandSelected?._id!,
        urlProfile: data.urlProfile,
      },
    });
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '500px',
          },
        }}
      >
        <Box p={4}>
          <Typography fontWeight={500} fontSize={24} marginBottom={1}>
            {t('Introduce el URL de tu perfil de Linkedin')}
          </Typography>
          <Controller
            name={'urlProfile'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                size="small"
                fullWidth
                required
                sx={inputStyle}
                variant="outlined"
                {...register('urlProfile')}
                onChange={onChange}
                value={value}
                placeholder={t('Placeholder url')}
                error={!!errors.urlProfile}
                helperText={errors.urlProfile?.message}
              />
            )}
          />

          <LoadingButton
            variant="contained"
            sx={{
              width: '120px',
              marginTop: 2,
              marginLeft: 'auto',
              float: 'right',
            }}
            onClick={handleSubmit(onSubmit)}
            loading={loadingAddUrl}
          >
            {t('Add')}
          </LoadingButton>
        </Box>
      </Dialog>
      {loading ? <LinearProgress /> : <Box height={4} />}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        spacing={2}
        p={4}
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography variant={'h4'} fontWeight="bold">
            {t('Analytics')}
          </Typography>
          <Typography variant={'body1'}>
            {t("See your posts' impact and fine-tune your strategy!")}
          </Typography>
          {user?.brandSelected?.typeBrand === TypeBrand.Personal &&
            !user?.brandSelected?.urlProfile && (
              <Box
                sx={{
                  backgroundColor: '#E4E4E4',
                  mt: 2,
                  p: 2,
                  width: '100%',
                }}
                display={'flex'}
                flexDirection={'row'}
                gap={2}
              >
                <Typography component="span" sx={{ display: 'inline' }}>
                  <span style={{ fontWeight: 'bold' }}>{t('Para que funcione')}</span>{' '}
                  <span style={{ color: '#3B5C84', fontWeight: 700 }}>
                    {t('Estaran disponibles')}
                  </span>
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: '150px',
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  {t('Add url')}
                </Button>
              </Box>
            )}
        </Stack>
      </Stack>

      <Stack direction="column" px={4}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{ borderBottom: 1, borderRadius: 0, borderColor: 'grey.100', mb: 4 }}
        >
          {tabsConfig.map((tabConfig, index) => (
            <Tab disableRipple key={index} label={tabConfig.label} sx={tabStyle} />
          ))}
        </Tabs>
        {tabsConfig.map((tabConfig, index) => (
          <TabPanel value={tab} index={index} sx={{ p: 0 }} key={index}>
            {tabConfig.content}
          </TabPanel>
        ))}
      </Stack>
    </>
  );
};

export default Analytics;
