import React, { useState } from 'react';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import PostsList from './PostsList';
import { useTranslation } from 'react-i18next';
import ShareInboxSettingsModal from '../../components/ShareInbox/ShareInboxSettings';
import { useMutation, useQuery } from '@apollo/client';
import { VALIDATE_BRAND_PASSWORD_INBOX } from '../../graphql/mutations';
import { GET_BRAND } from '../../graphql/queries';

const ManageTeam = () => {
  const { t } = useTranslation();
  const { brandId: brandParam } = useParams();
  const brandId = brandParam || null;

  const [openPasswordModal, setOpenPasswordModal] = useState(!!brandId); // Open modal only if brandId exists
  const [isAuthorized, setIsAuthorized] = useState(!brandId); // Allow access if no brandId
  const [error, setError] = useState(''); // Track error messages for the password
  const [validatePassword] = useMutation(VALIDATE_BRAND_PASSWORD_INBOX);

  // Query to fetch brand details if brandId exists
  const { data: brandData, loading: loadingBrand } = useQuery(GET_BRAND, {
    variables: { brandId: brandId as string },
    skip: !brandId, // Skip query if no brandId
  });

  const handlePasswordConfirm = async (password: string) => {
    if (brandId) {
      try {
        const { data } = await validatePassword({
          variables: { brandId, password },
        });
        if (data?.validateBrandPasswordInbox?.success) {
          setIsAuthorized(true); // Allow access to the brand
          setOpenPasswordModal(false);
          setError(''); // Clear any previous errors
        } else {
          setError(t('Invalid password. Please try again.'));
        }
      } catch (error) {
        setError(t('An unexpected error occurred. Please try again.'));
      }
    }
  };

  // Loading state while fetching brand details
  if (brandId && loadingBrand) {
    return (
      <Stack direction="column" p={3} gap={4} alignItems="center">
        <CircularProgress />
        <Typography>{t('Loading brand details...')}</Typography>
      </Stack>
    );
  }

  const brandName = brandData?.getBrand?.name || null;

  return (
    <Stack direction="column" p={3} gap={4}>
      {/* Password Modal */}
      {brandId && (
        <ShareInboxSettingsModal
          open={openPasswordModal}
          onClose={() => {
            setOpenPasswordModal(false);
            setError(''); // Reset error on close
          }}
          onConfirm={handlePasswordConfirm}
          mode="enter"
          title={t('Enter Password to continue')}
          error={error} // Pass the error message to the modal
        />
      )}

      {/* Show content only if authorized */}
      {isAuthorized && (
        <>
          <Stack direction="column" alignItems="flex-start">
            <Typography variant="h4" fontWeight="bold">
              {brandId && brandName ? brandName : t('Content Manager')}
            </Typography>
            <Typography variant="body1">
              {brandId && brandName
                ? t('Manage posts for this brand')
                : t('Create posts and manage your brands with ease')}
            </Typography>
          </Stack>
          <PostsList brandId={brandId} />
        </>
      )}
    </Stack>
  );
};

export default ManageTeam;
