import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useState } from 'react';
import { Post } from '../../__generated__/graphql';
import { UPDATE_FAVORITE_POST } from '../../graphql/mutations';
import { GET_CURRENT_BRAND_POSTS } from '../../graphql/queries';
import { DiscardPost } from '../PostComponents/DiscardPost';
import PostCard from '../PostComponents/PostCard';
import MasonryComponent from './MasonryComponent';

interface Props {
  posts: (Post | null)[];
  onPostOpen?: (postId: string) => void;
  handleEditPost?: (postId: string, userId: string) => void;
  setPostsToDelete: (id: string[]) => void;
  postsToDelete: string[];
  inboxLink?: boolean;
  previewShareInbox?: boolean;
}

const MasonryContentBoard = ({
  posts,
  onPostOpen = () => {},
  handleEditPost,
  setPostsToDelete,
  postsToDelete,
  inboxLink = false,
  previewShareInbox = false,
}: Props) => {
  const [deletePostId, setDeletePostId] = useState<string | null>(null);

  const [updateFavoritePost] = useMutation(UPDATE_FAVORITE_POST);

  return (
    <Box>
      <MasonryComponent>
        {posts.map(
          (post, i) =>
            post && (
              <PostCard
                key={i}
                post={post!}
                postsToDelete={postsToDelete}
                setPostsToDelete={setPostsToDelete}
                onClickFavoriteButton={() =>
                  updateFavoritePost({
                    variables: { postId: post?._id!, favorite: !post?.favorite },
                    refetchQueries: [
                      { query: GET_CURRENT_BRAND_POSTS },
                      'GetCurrentUserPosts',
                    ],
                  })
                }
                onClickDeleteButton={() => {
                  setDeletePostId(post?._id!);
                }}
                onClickShareButton={() => {
                  handleEditPost && handleEditPost(post?._id!, post?.brandId!);
                  onPostOpen(post?._id!);
                }}
                inboxLink={inboxLink}
                previewShareInbox={previewShareInbox}
              />
            ),
        )}
      </MasonryComponent>
      {deletePostId && (
        <DiscardPost postId={deletePostId} onCloseModal={() => setDeletePostId(null)} />
      )}
    </Box>
  );
};

export default MasonryContentBoard;
