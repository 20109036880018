import { useEffect } from 'react';

const useUnsavedChangesWarning = (hasUnsavedChanges: boolean, customMessage?: string, customAction?: () => void) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        const message = customMessage || 'You have unsaved changes. Are you sure you want to leave?';
        event.preventDefault();
        event.returnValue = message; // This is required for modern browsers
        if (customAction) {
          customAction();
        }
        return message; // For some older browsers

      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges, customMessage, customAction]);
};

export default useUnsavedChangesWarning;