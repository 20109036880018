import {
  Alert,
  AlertColor,
  Box,
  Snackbar,
  Button,
  Toolbar,
  Zoom,
  CardMedia,
  Stack,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Language, UserPlan } from '../../__generated__/graphql';
import NavBar from '../../components/NavBar.tsx/NavBar';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { SnackbarContext } from '../../context/SnackbarContext';
import { RoutesNames } from '../../types';
import { getDrawerWidth } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { HIDE_VIDEO_TUTORIAL } from '../../graphql/mutations';

interface customSnackbarProps {
  severity: AlertColor;
  message: string | null;
  handleClose: () => void;
}

const urlVideoES =
  'https://firebasestorage.googleapis.com/v0/b/magnettu-app.appspot.com/o/media%2F6426da4151ca85327335e632%2F1731443612190_Espa%C3%B1ol%20Onboarding%20video.mp4?alt=media&token=0282717b-54e9-4752-8f86-76985f95dc27';

const urlVideoEN =
  'https://firebasestorage.googleapis.com/v0/b/magnettu-app.appspot.com/o/media%2F672e8f381c619143324414cc%2F1731358914054_Ingle%CC%81s%20Onboarding%20video.mp4?alt=media&token=205dd90e-be27-483c-b2e8-dba10ebf85a1';

const Dashboard = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState<RoutesNames>('');
  const { user, setUser } = useContext(AuthContext);
  const { isMobile, isSmallDevice } = useContext(DeviceContext);
  const [openNavbar, setOpenNavbar] = useState(!isSmallDevice);
  const drawerWidth = getDrawerWidth(isMobile, isSmallDevice, openNavbar);
  const snackbar = useContext(SnackbarContext);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    setActive(pathname.split('/')[1] as RoutesNames);
  }, [pathname]);

  const snackbarTypes = [
    { severity: 'success', message: snackbar.successMessage },
    { severity: 'error', message: snackbar.errorMessage },
    { severity: 'warning', message: snackbar.warningMessage },
  ];

  const CustomSnackbar = ({ severity, message, handleClose }: customSnackbarProps) => (
    <Snackbar
      open={!!message}
      onClose={handleClose}
      data-cy={`${severity}-snackbar`}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      TransitionComponent={severity === 'error' ? Zoom : undefined}
    >
      <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    snackbar.setSuccessMessage(null);
    snackbar.setErrorMessage(null);
    snackbar.setWarningMessage(null);
  };

  const [hideVideoTutorial] = useMutation(HIDE_VIDEO_TUTORIAL, {
    onCompleted: (data) => {
      if (data.hideVideoTutorial?.success)
        setUser({ ...user!, showVideoTutorial: false });
    },
  });

  return (
    <>
      {!!user?.showVideoTutorial && (
        <Card
          sx={{
            position: 'fixed', // Fijar posición
            bottom: 16, // Separación del borde inferior
            right: { xs: 0, md: 16}, // Separación del borde derecho
            width: { xs: '100%', md: '400px' }, // Ancho ajustable
            maxHeight: '460px', // Altura máxima
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Sombra
            borderRadius: '10px', 
            zIndex: 1200, 
            overflow: 'hidden', 
          }}
        >
          <CardContent
            sx={{
              position: 'relative', 
            }}
          >
            {/* Botón de cerrar */}
            <IconButton
              onClick={() => {
                if (dontShowAgain) {
                  hideVideoTutorial();
                }
                setUser({ ...user!, showVideoTutorial: false });
              }}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 1,
              }}
            >
              <Close />
            </IconButton>
            <Typography fontWeight={700} fontSize={16}>
              {t('Do not know where to start?')}
            </Typography>

            <Divider sx={{ marginY: 2 }} />

            <Typography sx={{ marginBottom: 2 }} fontWeight={500} fontSize={14}>
              {t('Watch this tutorial to start working')}
            </Typography>

            {/* Video */}
            <CardMedia
              component="video"
              controls
              image={
                user?.language === Language.Spanish ||
                user?.language === Language.SpanishLatam
                  ? urlVideoES
                  : urlVideoEN
              }
              sx={{
                height: { xs: 'auto' },
                width: '100%',
              }}
            />

            <Divider sx={{ marginTop: 2 }} />

            <Stack flexDirection="column" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dontShowAgain}
                    onChange={() => setDontShowAgain(!dontShowAgain)}
                    sx={{
                      color: 'primary.main',
                      '&.Mui-checked': {
                        color: 'primary.main',
                      },
                      '&.MuiLabel-root': {
                        fontSize: 14,
                        fontWeight: 400,
                      },
                    }}
                  />
                }
                label={t('Do not show this tutorial again')}
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: '#00000099',
                  fontStyle: 'italic',
                  textAlign: 'center',
                }}
              >
                {t('If you need more help')}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      )}

      <NavBar
        currentPage={active}
        isSmallDevice={isSmallDevice}
        isMobile={isMobile}
        openNavbar={openNavbar}
        setOpenNavbar={setOpenNavbar}
      />
      <Box
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          transition: 'all 0.2s ease-in-out',
        }}
      >
        {isMobile && <Toolbar />}
        <Outlet />
      </Box>
      {snackbarTypes.map((snackbarType) => (
        <CustomSnackbar
          key={snackbarType.severity}
          severity={snackbarType.severity as AlertColor}
          message={snackbarType.message}
          handleClose={handleClose}
        />
      ))}

      {user?.plan && user?.plan.includes(UserPlan.Inactive) && (
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Alert
            severity="info"
            action={
              <Button color="inherit" onClick={() => navigate('/settings')}>
                {t('Go to Settings')}
              </Button>
            }
          >
            <strong>{t("You don't have any plan assigned.")}</strong>{' '}
            {t(
              'Go to settings to upgrade your account or contact us at info@magnettu.com',
            )}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default Dashboard;
