import { useQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Avatar,
  Badge,
  Box,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TagsType } from '../../__generated__/graphql';
import { PostContext } from '../../context/PostContext';
import { GET_ALL_BRANDS_TO_TAG } from '../../graphql/queries';

interface Props {
  open: boolean;
  anchorElement: HTMLButtonElement | null;
  onClose: () => void;
  onChange: (tag: string, tagId: string) => void;
}

const TaggerSelector = ({ open, anchorElement, onClose, onChange }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const { postState } = useContext(PostContext);

  const { data: usersToTagData, loading } = useQuery(GET_ALL_BRANDS_TO_TAG, {
    variables: {
      search: debouncedSearchTerm,
      postId: postState?.post._id || null,
    },
    fetchPolicy: 'network-only',
    skip: !open,
  });

  const usersToTag = usersToTagData?.getAllBrandsToTag;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleTagClick = (tag: string, tagId: string) => {
    onChange(tag, tagId);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);

  const users = (usersToTag || []).filter(
    (user) => user && user.type === TagsType.LinkedinUser,
  );

  const companies = (usersToTag || []).filter(
    (user) => user && user.type === TagsType.LinkedinCompany,
  );

  const noResultsFoundAlert = (
    <Alert severity={'info'}>
      No results found.{' '}
      <span style={{ textDecoration: 'underline' }}>
        <a
          href="https://magnettu.notion.site/Ayuda-para-menciones-14a4a0b58cd8808e9ef5d2bca0dd75b7"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click for more information
        </a>
      </span>
    </Alert>
  );

  return (
    <Popper
      open={open}
      anchorEl={anchorElement}
      sx={{ zIndex: 'modal', maxHeight: '450px' }}
      placement="bottom"
    >
      <ClickAwayListener onClickAway={onClose}>
        <Stack
          sx={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: 1,
            width: '350px',
            height: '450px',
            overflowY: 'auto',
            direction: 'column',
            gap: 1,
          }}
        >
          <Typography fontWeight={'bold'} fontSize={14} pb={1}>
            Tag a brand
          </Typography>
          <TextField
            type="text"
            autoFocus
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '100%',
              '.MuiInputBase-root': {
                height: '40px',
              },
            }}
          />
          {loading && (
            <CircularProgress
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                marginX: 'auto',
                height: '100%',
                width: '100%',
              }}
            />
          )}
          {!loading && !searchTerm && (
            <Alert severity={'info'} icon={false}>
              You can tag brands connected to magnettu and some public LinkedIn pages
            </Alert>
          )}
          {!loading && !companies.length && !users.length && noResultsFoundAlert}
          {!loading && (!!users.length || !!companies.length) && (
            <Box>
              <Typography variant="subtitle1" fontWeight={700}>
                Companies
              </Typography>
              <List>
                {companies.map((user, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleTagClick(user?.name!, user?.linkedinUrn!)}
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: '#f1f1f1',
                        borderRadius: '8px',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        // badgeContent={getIconByType(account.type)}
                      >
                        <Avatar alt={user?.name!!} src={user?.avatar || ''} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography>{user?.name}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              {!!users.length && !companies.length && noResultsFoundAlert}
              <Typography variant="subtitle1" fontWeight={700} mt={1}>
                Profiles
              </Typography>

              <List>
                {users.map((user, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleTagClick(user?.name!, user?.linkedinUrn!)}
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: '#f1f1f1',
                        borderRadius: '8px',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        // badgeContent={getIconByType(account.type)}
                      >
                        <Avatar alt={user?.name!!} src={user?.avatar || ''} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography>{user?.name}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              {!!companies.length && !users.length && noResultsFoundAlert}
            </Box>
          )}
        </Stack>
      </ClickAwayListener>
    </Popper>
  );
};

export default TaggerSelector;
