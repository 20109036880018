import { useMutation, useQuery } from '@apollo/client';
import { SnackbarContext } from '../../context/SnackbarContext';
import { Favorite, TipsAndUpdates } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import MagicWandIcon from '@mui/icons-material/AutoFixHigh';
import LoaderGif from '../../assets/spinner.gif';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  OrderByDirection,
  OrderByField,
  Post,
  PostLifecycleState,
  UserPlan,
  UserRole,
} from '../../__generated__/graphql';
import BrandSettingsModal from '../../components/BrandComponents/BrandSettingsModal';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import Header from '../../components/Layout/Header';
import MasonryComponent from '../../components/Masonry/MasonryComponent';
import MasonryContentBoard from '../../components/Masonry/MasonryContentBoard';
import PostEditorModal from '../../components/PostComponents/PostEditorModal';
import PostSkeleton from '../../components/PostComponents/PostSkeleton';
import Tabs from '../../components/Tabs/Tabs';
import { AuthContext } from '../../context/AuthContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostProvider } from '../../context/PostContext';
import { PostsFilterContext } from '../../context/PostsFilterContext';
import { WebSocketProvider } from '../../context/WebSocketContext';
import { HARD_DELETE_POSTS } from '../../graphql/mutations';
import { CREATE_POSTS_BRAND } from '../../graphql/mutations';
import { GET_CURRENT_BRAND_POSTS } from '../../graphql/queries';
import { ContentBoardTabs, getLifecycleStatesFromTab, OrderBy } from '../../types';
import SelectOrderPost from '../SelectOrder/SelectOrderPost';
import { useTranslation } from 'react-i18next';

enum PostFilterOptions {
  MyPost = 'My Post',
  ForYou = 'For You',
  Advocacy = 'Advocacy',
  Blog = 'Blog',
  Corporate = 'Corporate',
}

const LIMIT = 24;

const ContentBoard = () => {
  const { user, setUserHavePost } = useContext(AuthContext);
  const { setErrorMessage } = useContext(SnackbarContext);
  const { setSuccessMessage } = useContext(SnackbarContext);
  const { isMobile } = useContext(DeviceContext);

  const { state } = useLocation();

  const [openedPostId, setOpenedPost] = useState<string | null>(state?.postId || null);
  const [openNewPost, setOpenNewPost] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<PostFilterOptions[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasPostsApproved, setHasPostsApproved] = useState<boolean>(false);

  const [page, setPage] = useState(0);
  const [posts, setPosts] = useState<Post[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { filter, setFilter, currentTabs } = useContext(PostsFilterContext);
  const [orderBy, setOrderBy] = useState<OrderBy>({
    field: OrderByField.CreatedOn,
    direction: OrderByDirection.Desc,
  });
  const [postsToDelete, setPostsToDelete] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [canFetchMore, setCanFetchMore] = useState(false);
  const [loadingAutomation, setLoadingAutomation] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0); // Track progress percentage

  const brandSelected = user?.brandSelected;

  const { loading, error, data, refetch } = useQuery(GET_CURRENT_BRAND_POSTS, {
    variables: {
      filter,
      limit: LIMIT,
      page,
      orderBy,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        !currentTabs.includes(ContentBoardTabs.posted) &&
        data.currentBrandPosts.total
      ) {
        setUserHavePost(true);
      }

      if (page === 0) {
        setPosts(data.currentBrandPosts.posts || []);
      }

      if (page > 0) {
        const newPosts = data.currentBrandPosts.posts.filter(
          (newPost) => !posts.some((oldPost) => oldPost._id === newPost._id),
        );

        setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      }

      setHasPostsApproved(!!data.currentBrandPosts.havePostsApproved);
      setFetching(false);
      setCanFetchMore(data.currentBrandPosts.total / LIMIT > page + 1);
    },
  });

  const navigate = useNavigate();

  const [hardDeletePosts] = useMutation(HARD_DELETE_POSTS, {
    onCompleted: () => {
      refetch();
      setOpenModalDelete(false);
      setLoadingDelete(false);
      setPostsToDelete([]);
    },
  });

  const [createPostsBrand] = useMutation(CREATE_POSTS_BRAND, {
    onCompleted: (data) => {
      if (data.createPostsBrand?.success) {
        const successMessage =
          data?.createPostsBrand?.message ??
          'Posts created successfully in the draft section';
        refetch();
        setSuccessMessage(successMessage);
      } else {
        const errorMessage =
          data?.createPostsBrand?.message ??
          'Error: Failed to create posts for the brand.';
        setErrorMessage(errorMessage);
      }
      setLoadingAutomation(false);
    },
  });

  const handleHardDeletePosts = () => {
    setLoadingDelete(true);
    hardDeletePosts({
      variables: {
        postIds: postsToDelete,
      },
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setPostsToDelete([]);
    } else {
      setPostsToDelete(posts.map((post) => post._id!));
    }
  };

  const handelOpenPost = (postId: string) => {
    setOpenedPost(postId);
  };

  const handleOpenNewPost = () => {
    setOpenNewPost(true);
  };

  const handleCreatePosts = async () => {
    const brandId = user?.brandSelected?._id;
    if (brandId) {
      let progressInterval: NodeJS.Timeout;
      setLoadingAutomation(true);
      setProgress(0); // Reset progress to 0

      // Simulate progress over 120 seconds (2 minutes)
      const totalTime = 40 * 1000; // 40 seconds in milliseconds
      const intervalTime = 500; // Update every 0.5 seconds
      let elapsedTime = 0;

      progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          elapsedTime += intervalTime;
          const newProgress = (elapsedTime / totalTime) * 100; // Calculate progress percentage
          return newProgress >= 100 ? 100 : newProgress; // Stop at 100%
        });

        if (elapsedTime >= totalTime) {
          clearInterval(progressInterval); // Stop updating after 70 seconds
        }
      }, intervalTime);

      // Call the mutation for creating posts
      await createPostsBrand({
        variables: { brandId: brandId },
      });
      clearInterval(progressInterval);
      setProgress(100); // Set progress to 100% on completion
    }
  };

  const handlePostEditorClose = (closedPost?: Post) => {
    if (page === 0) refetch();
    else setPage(0);
    setOpenNewPost(false);
    setOpenedPost(null);
  };

  const toggleFilterOption = (option: PostFilterOptions) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleChangeTab = (tabs: ContentBoardTabs[]) => {
    // setPosts([]);
    setPage(0);
    setFilter({
      lifecycleStates: getLifecycleStatesFromTab(tabs),
    });
  };

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const isScrollAtTheEnd = scrollTop + clientHeight >= scrollHeight;

    if (!loading && isScrollAtTheEnd && canFetchMore && !fetching) {
      setFetching(() => true);
      setPage(page + 1);
    }
  }, [page, loading, canFetchMore, fetching]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const { t } = useTranslation();

  const textNoPost = () => {
    if (currentTabs.includes(ContentBoardTabs.drafts) || user?.plan?.includes(UserPlan.Starter))
      return t(
        'Looks like you havent crafted your first post yet',
      );

    if (currentTabs.includes(ContentBoardTabs.inbox))
      return t(
        'No posts are available yet, but we are preparing some suggestions for you. Stay tuned.',
      );

    return t('No posts found for these filters. Try changing your selection.');
  };

  if (
    !user?.brand &&
    !user?.role.includes(UserRole.BrandManager) &&
    !user?.role.includes(UserRole.LimitedBrandManager)
  )
    return (
      <Stack direction={'column'} textAlign={'center'}>
        <Box
          sx={{
            borderRadius: '20px',
            padding: '20px',
            width: '100%',
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Typography fontSize={26} fontWeight={'700'}>
            {t('Welcome to Magnettu')}
          </Typography>
          <img
            src="../images/cajaBrand.png"
            alt="onboarding"
            style={{ width: '85px', height: '85px' }}
          />
          <Typography
            sx={{
              // width: '50%',
              textAlign: 'center',
              marginTop: '10px',
            }}
            width={{ xs: '100%', sm: '100%', md: '50%' }}
          >
            {t(
              'It’s time to set up your brand. By establishing your own brand and entering the necessary details, you’ll streamline your workflow and leverage our tools to their fullest potential. Please create your brand to begin optimizing your operations.',
            )}
          </Typography>

          <Button variant="contained" onClick={() => setOpenModal(true)}>
            {t('Create your brand')}
          </Button>
        </Box>

        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          fullScreen={isMobile}
          PaperProps={{
            sx: {
              width: '800px',
              maxWidth: '800px !important',
            },
          }}
        >
          <BrandSettingsModal onClose={() => setOpenModal(false)} />
        </Dialog>
      </Stack>
    );

  const textHeader = () => {
    switch (currentTabs[0]) {
      case ContentBoardTabs.posted:
        return t('Here you will find all the content that has been posted on LinkedIn.');
      case ContentBoardTabs.drafts:
        return t(
          'Your ideation section. You can create drafts for yourself or your team and assign them a tentative posting date. Keep in mind that the draft content will not be posted unless you schedule it.',
        );
      case ContentBoardTabs.inbox:
        return t(
          'Here you will receive content that has been created for you, whether it is created by Magnettu or someone else on your team.',
        );
      case ContentBoardTabs.scheduled:
        return t(
          'Here you will see all the content that has been scheduled and will be posted on LinkedIn on the selected dates.',
        );
      case ContentBoardTabs.approved:
        return t(
          'This is where, as a content editor or manager, you’ll see the content that has been approved by your teams or clients.',
        );
      case ContentBoardTabs.discarded:
        return t('Here you will see all the content that has been dismissed.');
      default:
        return t('We have curated content just for you.');
    }
  };

  return (
    <Box width={'100%'} p={isMobile ? '15px 25px' : '40px 25px'}>
      {/* Loader Spinner Overlay */}
      {loadingAutomation && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '150%', // Full viewport height
            backgroundColor: 'rgba(250, 251, 252, 0.9)', // Semi-transparent background with color FAFBFC and 90% transparency
            display: 'flex',
            alignItems: 'center', // Vertical alignment
            justifyContent: 'center', // Horizontal alignment
            zIndex: 10,
            flexDirection: 'column', // Stack items vertically
            gap: 1, // Adjust gap between elements
            textAlign: 'center', // Center the text horizontally
            paddingLeft: '200px',
            marginTop: '-250px',
            paddingTop: '0px',
          }}
        >
          <Box
            width={{
              xs: '100%', // Full width on mobile
              md: '600px', // Half width on desktop
            }}
            height="400px" // Set a fixed height for the image
          >
            <img
              src={LoaderGif}
              alt="Loading"
              style={{
                width: '100%', // Let the image resize automatically
                height: '400px', // You can adjust this based on your needs
                objectFit: 'contain',
              }}
            />
          </Box>

          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontWeight: 'bold', marginTop: '-100px' }} // Pull the text closer to the image
          >
            {`${Math.round(progress)}%`} {/* Display percentage */}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {t('Give us a moment to work our magic.')}
            <br />
            {t('We´re analyzing over 200,000 viral posts to craft yours.')}
          </Typography>
        </Box>
      )}
      <Stack
        data-testid="content-board"
        direction="column"
        rowGap={2}
        maxWidth={'1800px'}
        alignSelf={'center'}
        marginX={'auto'}
      >
        <Header text={textHeader()} />
        <Stack
          direction={{ md: 'row', sm: 'column-reverse' }}
          justifyContent={'space-between'}
          width={'100%'}
          alignItems={{ sm: 'flex-start', md: 'center' }}
          gap={{ sm: 1, md: 0 }}
        >
          <Tabs
            selectedTabs={currentTabs}
            setSelectedTabs={handleChangeTab}
            showTabApproved={hasPostsApproved}
            showTabInbox={!user?.plan?.includes(UserPlan.Starter)}
          />

          {!isMobile && (
            <Stack flexDirection={{ sm: 'row-reverse', md: 'row' }} gap={0.5}>
              <Button
                variant="outlined"
                onClick={() => {
                  setFilter({ ...filter, favorite: !filter.favorite });
                }}
                sx={{
                  backgroundColor: filter.favorite ? '#E7EBF0' : '#FFF',
                }}
              >
                <Favorite />
              </Button>

              <Dialog
                id="simple-menu"
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                  sx: {
                    overflow: 'visible',
                    width: 'auto',
                    minWidth: '300px',
                    boxShadow: '0px 11px 14px 0px rgba(0, 0, 0, 0.13)',
                    borderRadius: '5px',
                    padding: '1rem',
                  },
                }}
              >
                <Stack flexDirection="row">
                  <Box>
                    <Typography
                      fontWeight="bold"
                      sx={{
                        paddingLeft: '20px',
                        color: '#E7EBF0 ',
                        marginY: '0.5rem',
                      }}
                    >
                      {t('Content filter')}
                    </Typography>
                    {Object.values(PostFilterOptions).map((filter, i) => (
                      <MenuItem key={i} onClick={() => toggleFilterOption(filter)}>
                        <Checkbox
                          sx={{ marginRight: '5px' }}
                          checked={selectedOptions.includes(filter)}
                        />
                        <Typography fontWeight="bold">{t(filter)}</Typography>
                      </MenuItem>

                      // )
                    ))}
                  </Box>
                </Stack>
                <Box
                  flexDirection="row"
                  display="flex"
                  justifyContent="flex-end"
                  marginTop="1rem"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAnchorEl(null)}
                    data-testid="new-advocacy-post-button"
                    sx={{
                      marginLeft: '5px',
                    }}
                  >
                    <Typography fontWeight="bold">{t('Done')}</Typography>
                  </Button>
                </Box>
              </Dialog>
              <Stack flexDirection="column">
                {user.role?.includes(UserRole.Creator) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleCreatePosts}
                    data-testid="get-posts-now-button"
                    sx={{
                      backgroundColor: 'white',
                      justifyContent: 'center',
                    }}
                    startIcon={<MagicWandIcon color="primary" />}
                  >
                    <Typography fontWeight="bold" noWrap color="primary">
                      {t('Get a post now')}
                    </Typography>
                  </Button>
                )}
                {user.plan?.includes(UserPlan.Starter) && (
                  <Typography
                    sx={{
                      color: '#FF007A',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginTop: '5px',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      navigate('/settings');
                    }}
                  >
                    <TipsAndUpdates
                      sx={{
                        color: '#FF007A',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginTop: '10px',
                        cursor: 'pointer',
                        marginRight: '5px',
                      }}
                    />
                    {t('Pro tip: Set up your content settings here')}
                  </Typography>
                )}
              </Stack>

              {user.role?.includes(UserRole.Creator) && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenNewPost}
                  data-testid="new-advocacy-post-button"
                  disabled={!!!user?.isActive}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  <Typography fontWeight="bold">{t('Create Post')}</Typography>
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        {isMobile && (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCreatePosts}
              data-testid="get-posts-now-button"
              sx={{
                backgroundColor: 'white',
                justifyContent: 'center',
              }}
              startIcon={<MagicWandIcon color="primary" />}
            >
              <Typography fontWeight="bold" noWrap color="primary">
                {t('A post now')}
              </Typography>
            </Button>

            {user.plan?.includes(UserPlan.Starter) && (
              <Typography
                sx={{
                  color: '#FF007A',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  // marginTop: '5px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  navigate('/settings');
                }}
              >
                <TipsAndUpdates
                  sx={{
                    color: '#FF007A',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginTop: '10px',
                    cursor: 'pointer',
                    marginRight: '5px',
                  }}
                />
                {t('Pro tip: Set up your content settings here')}
              </Typography>
            )}
          </>
        )}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <SelectOrderPost orderBy={orderBy} setOrderBy={setOrderBy} />
        </Stack>

        {loading ? (
          posts.length ? (
            <>
              <MasonryContentBoard
                posts={posts || []}
                postsToDelete={postsToDelete}
                setPostsToDelete={setPostsToDelete}
              />
            </>
          ) : (
            <MasonryComponent>
              {[...Array(10)].map((_, i) => (
                <PostSkeleton key={i} />
              ))}
            </MasonryComponent>
          )
        ) : error ? (
          <Typography>{t('There was an unexpected error. Please try again.')}</Typography>
        ) : posts.length ? (
          <>
            {filter.lifecycleStates?.includes(PostLifecycleState.Discarded) && (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll && postsToDelete.length === posts.length}
                    />
                  }
                  label={t('Select all')}
                  onChange={handleSelectAll}
                />
                <Button
                  onClick={() => {
                    setOpenModalDelete(true);
                  }}
                  disabled={!postsToDelete.length}
                  variant="contained"
                  sx={{
                    backgroundColor: '#D32F2F',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#D32F2F',
                    },
                  }}
                >
                  {t('Delete')} (
                  {selectAll ? data?.currentBrandPosts.total : postsToDelete.length})
                </Button>
              </Stack>
            )}
            <MasonryContentBoard
              posts={posts}
              onPostOpen={(postId) => handelOpenPost(postId)}
              setPostsToDelete={setPostsToDelete}
              postsToDelete={postsToDelete}
            />
          </>
        ) : (
          <Typography>{textNoPost()}</Typography>
        )}
      </Stack>
      {(!!openedPostId || openNewPost) && (
        <WebSocketProvider postId={openedPostId}>
          <PostProvider
            postId={openedPostId}
            newPostOptions={{
              brandId: brandSelected?._id,
              brand: brandSelected,
            }}
          >
            <PostEditorModal onClose={handlePostEditorClose} />
          </PostProvider>
        </WebSocketProvider>
      )}
      <ConfirmationDialog
        title={t('Delete posts')}
        content={
          <>
            <Divider
              sx={{
                marginBottom: '10px',
              }}
            />
            {t(
              'Deleting this post will make it permanently unavailable. This action cannot be undone, and all associated posts will be lost forever. Do you wish to proceed?',
            )}
            <Divider
              sx={{
                marginTop: '10px',
              }}
            />
          </>
        }
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onConfirm={handleHardDeletePosts}
        okText={t('Delete')}
        deleteButton
        loading={loadingDelete}
      />
    </Box>
  );
};

export default ContentBoard;
