import { useMutation, useQuery } from '@apollo/client';
import { CalendarToday, Close, FilterAlt, GridView, List } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ShareIcon from '@mui/icons-material/Share';
import SearchIcon from '@mui/icons-material/Search';
import MagicWandIcon from '@mui/icons-material/AutoFixHigh';
import { SnackbarContext } from '../../context/SnackbarContext';
import LoaderGif from '../../assets/spinner.gif';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  OrderByDirection,
  OrderByField,
  Post,
  PostLifecycleState,
  UserRole,
} from '../../__generated__/graphql';
import Avatar from '../../components/Avatar/Avatar';
import { AvatarGroup } from '../../components/Avatar/AvatarGroup';
import MyCalendar from '../../components/Calendar/Calendar';
import DemoCard from '../../components/Cards/DemoCard';
import PostDemoCard from '../../components/Cards/PostDemoCard';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { PostsFilter } from '../../components/Filters/PostsFilter';
import MasonryContentBoard from '../../components/Masonry/MasonryContentBoard';
import { DiscardPost } from '../../components/PostComponents/DiscardPost';
import PostEditorModal from '../../components/PostComponents/PostEditorModal';
import PostsTeamTable from '../../components/Tables/PostsTeamTable';
import ShareInboxSettingsModal from '../../components/ShareInbox/ShareInboxSettings';
import Tabs from '../../components/Tabs/Tabs';
import { BrandContext } from '../../context/BrandContext';
import { DeviceContext } from '../../context/DeviceContext';
import { PostProvider } from '../../context/PostContext';
import { AuthContext } from '../../context/AuthContext';
import { FilterTab, PostsFilterContext } from '../../context/PostsFilterContext';
import { WebSocketProvider } from '../../context/WebSocketContext';
import { HARD_DELETE_POSTS } from '../../graphql/mutations';
import { CREATE_POSTS_BRAND } from '../../graphql/mutations';
import { GET_POSTS_BY_SHELL } from '../../graphql/queries';
import { CalendarEvent, getLifecycleStatesFromTab, OrderBy } from '../../types';
import SelectOrderPost from '../SelectOrder/SelectOrderPost';
import { useTranslation } from 'react-i18next';
import { ENABLE_SHARE_LINK_INBOX } from '../../graphql/mutations';

enum ViewOptions {
  'grid',
  'list',
  'calendar',
}

const LIMIT = 24;

interface Props {
  brandId: string | null;
}

const PostsList = ({ brandId }: Props) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useContext(SnackbarContext);
  const { setSuccessMessage } = useContext(SnackbarContext);
  // Context
  const { filter, setFilter, isFilterActive, setFilterTab, currentTabs } =
    useContext(PostsFilterContext);
  const { dataBrands, handleOpenModal } = useContext(BrandContext);
  const { isMobile } = useContext(DeviceContext);
  const { user } = useContext(AuthContext);

  // State
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [openSelectUser, setOpenSelectUser] = useState<boolean>(false);
  const [openedPostId, setOpenedPost] = useState<string | null>(null);
  const [openNewPost, setOpenNewPost] = useState<boolean>(false);
  const [selectedBrandId, setSelectedBrandId] = useState<string | null>(null);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(15);
  const [orderBy, setOrderBy] = useState<OrderBy>({
    field: OrderByField.CreatedOn,
    direction: OrderByDirection.Desc,
  });
  const [view, setView] = useState<ViewOptions>(ViewOptions.grid);
  const [fetching, setFetching] = useState(false);
  const [searchBrand, setSearchBrand] = useState('');
  const [searchNewPostBrand, setSearchNewPostBrand] = useState('');
  const [posts, setPosts] = useState<Post[]>([]);
  const [newPostSchedule, setNewPostSchedule] = useState<Date | null>(null);
  const [deletePostId, setDeletePostId] = useState<string | null>(null);
  const [postsToDelete, setPostsToDelete] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [canFetchMore, setCanFetchMore] = useState(false);
  const [is4Posts, setIs4Posts] = useState<boolean>(false);
  const [loadingAutomation, setLoadingAutomation] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0); // Track progress percentage
  const [openShareInboxModal, setOpenShareInboxModal] = useState(false);

  // Queries
  const { loading, data, refetch } = useQuery(GET_POSTS_BY_SHELL, {
    variables: {
      filter: {
        ...filter,
        ...(brandId
          ? {
              brandIds: [brandId],
              lifecycleStates: filter.lifecycleStates?.length
                ? filter.lifecycleStates
                : [
                    PostLifecycleState.PendingApproval,
                    PostLifecycleState.Approved,
                    PostLifecycleState.Ready,
                    PostLifecycleState.Scheduled,
                    PostLifecycleState.Draft,
                    PostLifecycleState.Discarded,
                  ],
            }
          : {}),
      },
      orderBy,
      page: page,
      limit: view === ViewOptions.grid ? LIMIT : view === ViewOptions.list ? limit : -1,
      isLinkInbox: !!brandId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (view !== ViewOptions.grid) {
        setPosts(data.postsByShell.posts || []);
      }

      if (selectAll) {
        const uniquePostsToDelete = new Set(
          data.postsByShell.posts.map((post) => post._id),
        );
        setPostsToDelete(Array.from(uniquePostsToDelete));
      }

      if (view === ViewOptions.grid) {
        setCanFetchMore(data.postsByShell.total / LIMIT > page + 1);
      }

      if (page === 0 && view === ViewOptions.grid) {
        setPosts(data.postsByShell.posts || []);
      }

      if (page > 0 && view === ViewOptions.grid) {
        const newPosts = data.postsByShell.posts.filter(
          (newPost) => !posts.some((oldPost) => oldPost._id === newPost._id),
        );

        setPosts((prevPosts) => [...prevPosts, ...newPosts]);
      }

      setFetching(false);
    },
  });

  // Mutations
  const [hardDeletePosts] = useMutation(HARD_DELETE_POSTS, {
    onCompleted: () => {
      refetch();
      setOpenModalDelete(false);
      setLoadingDelete(false);
      setPostsToDelete([]);
    },
  });

  const [enableShareLinkInbox] = useMutation(ENABLE_SHARE_LINK_INBOX);

  const [createPostsBrand] = useMutation(CREATE_POSTS_BRAND, {
    onCompleted: (data) => {
      if (data.createPostsBrand?.success) {
        const successMessage =
          data?.createPostsBrand?.message ??
          'Posts created successfully in the draft section';
        refetch();
        setSuccessMessage(successMessage);
      } else {
        const errorMessage =
          data?.createPostsBrand?.message ??
          'Error: Failed to create posts for the brand.';
        setErrorMessage(errorMessage);
      }
      setLoadingAutomation(false);
      setOpenSelectUser(false);
    },
  });

  const handleHardDeletePosts = () => {
    setLoadingDelete(true);
    hardDeletePosts({
      variables: {
        postIds: postsToDelete,
      },
    });
  };

  const postExists = !!posts.length;

  //Constants
  const filteredBrands = dataBrands.filter((brand) =>
    brand.name.toLocaleLowerCase().includes(searchBrand.toLowerCase()),
  );

  const filteredBrandsNewPost = dataBrands.filter((brand) =>
    brand.name.toLocaleLowerCase().includes(searchNewPostBrand.toLowerCase()),
  );

  const selectedBrand =
    dataBrands?.find((brand) => brand?._id === selectedBrandId) || null;

  let debounceTimer: NodeJS.Timeout | number;

  // Functions
  const handlePostEditorClose = () => {
    if (page === 0) refetch();
    else setPage(0);
    setOpenNewPost(false);
    setOpenedPost(null);
  };

  const handleCloseModalSelectUser = () => {
    setOpenSelectUser(false);
    setSelectedBrandId(null);
  };

  const handleEditPost = (postId: string, brandId?: string) => {
    setOpenedPost(postId);
  };

  const handleCopyLinkShareInbox = (brandId: string, password: string) => {
    const link = `${window.location.origin}/inbox/${brandId}`;
    navigator.clipboard.writeText(link);
    setSuccessMessage(t('Inbox link copied to your clipboard'));

    if (selectedBrand) {
      enableShareLinkInbox({
        variables: {
          brandId: brandId,
          password: password,
        },
      });
    }
  };

  const handlePasswordConfirm = (password: string) => {
    if (selectedBrandId) {
      handleCopyLinkShareInbox(selectedBrandId, password); // Share inbox with the selected password
      setOpenPasswordModal(false);
    }
  };

  const handleSelectBrand = () => {
    if (selectedBrandId) {
      setOpenShareInboxModal(false);
      setOpenPasswordModal(true);
    }
  };

  const onSearchBrand = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBrand(e.target.value);
  };

  // Asegúrate de limpiar el timer cuando el componente se desmonte
  useEffect(() => {
    return () => {
      // @ts-ignore
      clearTimeout(debounceTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setPostsToDelete([]);
    } else {
      setPostsToDelete(posts.map((post) => post._id!));
    }
  };

  const handleScroll = useCallback(() => {
    if (view !== ViewOptions.grid) return;
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const isScrollAtTheEnd = scrollTop + clientHeight >= scrollHeight;

    if (!loading && isScrollAtTheEnd && canFetchMore && !fetching) {
      setFetching(() => true);
      setPage(page + 1);
    }
  }, [view, page, loading, canFetchMore, fetching]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleChangeView = (view: ViewOptions) => {
    setView(view);
    setPage(0);
    setOrderBy({
      field: OrderByField.CreatedOn,
      direction: OrderByDirection.Desc,
    });

    if (view === ViewOptions.calendar) {
      setFilter({
        ...filter,
        dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1).setDate(
          -6,
        ),
        dateTo: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setDate(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate() + 7,
        ),
      });

      setLimit(-1);
      return;
    }

    setFilter({
      ...filter,
      dateFrom: undefined,
      dateTo: undefined,
    });

    setLimit(15);
  };

  const handleModalNewPost = () => {
    if (brandId) return;
    setSearchNewPostBrand('');
    setOpenSelectUser(true);
    setIs4Posts(false);
  };

  const handleModalPosts = () => {
    setSearchNewPostBrand('');
    setOpenSelectUser(true);
    setIs4Posts(true);
  };

  const events: CalendarEvent[] = posts.map((post) => ({
    id: post._id,
    date:
      post.lifecycleState === PostLifecycleState.Posted ? post.postedAt : post.schedule,
    lifecycleState: post.lifecycleState!,
    lifecycleStateDisplay: post.lifecycleStateDisplay!,
    postingAccountName: post.brandName || '',
    avatar: post.brand?.account?.avatar || '',
    backgroundColor: post.brand?.backgroundColor || '',
    brandId: post.brandId || '',
    title: post.title || '',
    isIdea: post.isIdea || false,
    preview: post.content?.preview || '',
  }));

  type ValidPostLifecycleState =
    | PostLifecycleState.Posted
    | PostLifecycleState.Draft
    | PostLifecycleState.Ready
    | PostLifecycleState.Scheduled
    | PostLifecycleState.Approved
    | PostLifecycleState.Discarded;

  const lifecycleMessages: Record<ValidPostLifecycleState, string> = {
    [PostLifecycleState.Posted]: t(
      'Here you will find all the content that has been posted on LinkedIn.',
    ),
    [PostLifecycleState.Draft]: t(
      'Your ideation section. You can create drafts for yourself or your team and assign them a tentative posting date. Keep in mind that the draft content will not be posted unless you schedule it.',
    ),
    [PostLifecycleState.Ready]: t(
      'Here you will receive content that has been created for you, whether it is created by Magnettu or someone else on your team.',
    ),
    [PostLifecycleState.Scheduled]: t(
      'Here you will see all the content that has been scheduled and will be posted on LinkedIn on the selected dates.',
    ),
    [PostLifecycleState.Approved]: t(
      'This is where, as a content editor or manager, you’ll see the content that has been approved by your teams or clients.',
    ),
    [PostLifecycleState.Discarded]: t(
      'Here you will see all the content that has been dismissed.',
    ),
  };

  const textHeader = () => {
    const states = filter.lifecycleStates || [];

    for (const state of states) {
      if (state && state in lifecycleMessages) {
        return lifecycleMessages[state as ValidPostLifecycleState];
      }
    }

    return t('We have curated content just for you.');
  };

  return (
    <Stack direction="column" spacing={1}>
      {/* Loader Spinner Overlay */}
      {loadingAutomation && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '150%', // Full viewport height
            backgroundColor: 'rgba(250, 251, 252, 0.9)', // Semi-transparent background with color FAFBFC and 90% transparency
            display: 'flex',
            alignItems: 'center', // Vertical alignment
            justifyContent: 'center', // Horizontal alignment
            zIndex: 10,
            flexDirection: 'column', // Stack items vertically
            gap: 1, // Adjust gap between elements
            textAlign: 'center', // Center the text horizontally
            paddingLeft: '200px',
            marginTop: '-250px',
            paddingTop: '0px',
          }}
        >
          <img
            src={LoaderGif}
            alt="Loading"
            style={{
              width: '600px', // Let the image resize automatically
              height: '400px', // You can adjust this based on your needs
              objectFit: 'contain',
            }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontWeight: 'bold', marginTop: '-100px' }} // Pull the text closer to the image
          >
            {`${Math.round(progress)}%`} {/* Display percentage */}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {t('Give us a moment to work our magic.')}
            <br />
            {t('We´re analyzing over 200,000 viral posts to craft yours.')}
          </Typography>
        </Box>
      )}

      <Card
        sx={{
          padding: '17px 25px',
          borderRadius: '8px',
          marginBottom: '20px',
          boxShadow: '0px 0px 3.6700000762939453px 0px #0000001A',
        }}
      >
        <Stack direction="column" width="100%" textAlign="center" gap={1}>
          <Stack
            direction={{ sm: 'column-reverse', md: 'row' }}
            justifyContent="space-between"
            flex={1}
            gap={1}
          >
            {!isMobile && !brandId && (
              <Stack direction={'row'} gap={1}>
                <TextField
                  placeholder={t('Search brands')}
                  value={searchBrand}
                  onChange={onSearchBrand}
                  disabled={!dataBrands.length}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                      width: '220px',
                      backgroundColor: 'rgba(244, 244, 244, 1)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <AvatarGroup
                  titleTooltip="Go to filters"
                  infoTooltip={
                    filteredBrands.length > 1
                      ? t('Use Right click to select multiple status')
                      : undefined
                  }
                  max={6}
                  avatars={filteredBrands.map((brand) => ({
                    id: brand._id!,
                    name: brand.name || '',
                    avatar: brand.account?.avatar || '',
                    backgroundColor: brand.backgroundColor || '',
                    active: !!filter?.brandIds?.includes(brand._id),
                  }))}
                  onClickSurplus={() => {
                    setFiltersOpen(true);
                    setFilterTab(FilterTab.Brands);
                  }}
                  onClick={(id) => {
                    setSearchBrand('');
                    if (filter.brandIds?.length === 1 && filter.brandIds.at(0) === id) {
                      setFilter({ ...filter, brandIds: undefined });
                      return;
                    }

                    setFilter({ ...filter, brandIds: [id] });
                  }}
                  onContextMenu={(id) => {
                    setSearchBrand('');
                    const brandIds = filter.brandIds || [];
                    const isBrandIdPresent = brandIds.includes(id);
                    const updatedBrandIds = isBrandIdPresent
                      ? brandIds.filter((brandId) => brandId !== id)
                      : [...brandIds, id];
                    setFilter({ ...filter, brandIds: updatedBrandIds });
                  }}
                />
              </Stack>
            )}

            <Stack
              direction={{ sm: 'row-reverse', md: 'row', xs: 'column-reverse' }}
              gap={1}
              marginX={{ xs: 'auto', sm: '0' }}
              justifyContent={'flex-end'}
            >
              {(!!dataBrands.length || !postExists || brandId) && (
                <ButtonGroup>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleChangeView(ViewOptions.grid)}
                    data-testid="filter-button"
                    sx={{
                      backgroundColor: view === ViewOptions.grid ? '#FFD8EB' : 'white',
                    }}
                  >
                    <GridView />
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleChangeView(ViewOptions.list)}
                    data-testid="filter-button"
                    sx={{
                      backgroundColor: view === ViewOptions.list ? '#FFD8EB' : 'white',
                    }}
                  >
                    <List />
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleChangeView(ViewOptions.calendar)}
                    data-testid="filter-button"
                    sx={{
                      backgroundColor:
                        view === ViewOptions.calendar ? '#FFD8EB' : 'white',
                    }}
                  >
                    <CalendarToday />
                  </Button>
                </ButtonGroup>
              )}
              {isMobile && !brandId && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleModalPosts}
                  data-testid="get-posts-now-button"
                  sx={{
                    backgroundColor: 'white',
                    justifyContent: 'center',
                  }}
                  startIcon={<MagicWandIcon color="primary" />}
                >
                  <Typography fontWeight="bold" noWrap color="primary">
                    {t('Get a post now')}
                  </Typography>
                </Button>
              )}

              <Stack flexDirection="row" gap={0.5}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setFiltersOpen(true)}
                  disabled={!dataBrands.length}
                  data-testid="filter-button"
                  sx={{
                    backgroundColor: isFilterActive ? '#FFD8EB' : 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '4px 8px', // Adjust padding to make it more compact
                    minWidth: 'auto', // Remove default minWidth to shrink the button
                    display: 'flex',
                  }}
                >
                  <FilterAlt sx={{ margin: 0 }} />{' '}
                  {/* Remove margin to make icon more compact */}
                </Button>
                {!isMobile && !brandId && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleModalPosts}
                    data-testid="get-posts-now-button"
                    sx={{
                      backgroundColor: 'white',
                      justifyContent: 'center',
                    }}
                    startIcon={<MagicWandIcon color="primary" />}
                  >
                    <Typography fontWeight="bold" noWrap color="primary">
                      {t('Get a post now')}
                    </Typography>
                  </Button>
                )}

                {!brandId && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalNewPost}
                    data-testid="new-post-button"
                    startIcon={<AddIcon />}
                  >
                    <Typography fontWeight="bold" noWrap>
                      {t('New post for')}
                    </Typography>
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
          {!isMobile && (
            <>
              <Divider orientation="horizontal" flexItem />
              <Stack direction="row" justifyContent="space-between">
                {!brandId && (
                  <Stack direction="column" alignContent={'start'}>
                    <Tabs
                      showTabApproved
                      selectedTabs={currentTabs}
                      setSelectedTabs={(tabs) => {
                        setPage(0);
                        setLimit(LIMIT);
                        setFilter({
                          ...filter,
                          lifecycleStates: getLifecycleStatesFromTab(tabs),
                        });
                      }}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      textAlign="left"
                      marginTop={1}
                    >
                      {textHeader()}
                    </Typography>
                  </Stack>
                )}
                <Stack direction="row" gap={1}>
                  <SelectOrderPost orderBy={orderBy} setOrderBy={setOrderBy} />
                  {!brandId &&
                    (user?.role?.includes(UserRole.Admin) ||
                      user?.role?.includes(UserRole.BrandManager) ||
                      user?.role?.includes(UserRole.LimitedBrandManager)) && (
                      <Box>
                        <Tooltip
                          title={t('Share your content calendar in all views')}
                          placement="bottom"
                        >
                          <Button
                            variant="outlined"
                            onClick={() => setOpenShareInboxModal(true)}
                            sx={{
                              padding: '4px 8px', // Adjust padding to make it more compact
                              minWidth: 'auto', // Remove default minWidth to shrink the button
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <ShareIcon sx={{ fontSize: '20px' }} /> {/* Smaller icon */}
                            {/** 
                            {!openShareInboxModal &&
                              !openPasswordModal &&
                              !openSelectUser &&
                              !filtersOpen &&
                              !loadingAutomation && (
                                <Chip
                                  label="New"
                                  color="primary"
                                  size="small"
                                  sx={{
                                    position: 'absolute',
                                    top: '-13px',
                                    right: '-20px',
                                    height: '16px',
                                    minWidth: 'auto',
                                    borderRadius: '8px',
                                    padding: '0 2px',
                                    lineHeight: '16px',
                                    zIndex: 1500,
                                    '& .MuiChip-label': {
                                      fontFamily: 'Raleway',
                                      fontSize: '7px',
                                      fontWeight: 400,
                                      lineHeight: '18px',
                                      letterSpacing: '0.16px',
                                      textAlign: 'left',
                                      textUnderlinePosition: 'from-font',
                                      textDecorationSkipInk: 'none',
                                    },
                                  }}
                                />
                              )}
                                */}
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Card>
      <PostsFilter
        setPage={setPage}
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        isLinkInbox={!!brandId}
      />
      <Dialog open={openShareInboxModal} onClose={() => setOpenShareInboxModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '15px',
            cursor: 'pointer',
          }}
        >
          <IconButton onClick={() => setOpenShareInboxModal(false)}>
            <Close />
          </IconButton>
        </Box>
        <form
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'right',
            width: '100%',
          }}
          onSubmit={(e) => e.preventDefault()}
        >
          {!brandId && (
            <Stack direction="column" sx={{ width: '500px', p: 3 }}>
              <Typography fontWeight={700} fontSize="25px">
                {t('Choose a Brand to Share Inbox')}
              </Typography>
              <Divider
                sx={{
                  marginY: '10px',
                }}
              />
              <Box maxHeight="500px" overflow="auto">
                <Typography fontWeight={700} my={1}>
                  {t('Brand')}
                </Typography>
                <Box width="100%" justifyContent="center">
                  <TextField
                    sx={{
                      width: '100%',
                    }}
                    placeholder={t('Search')}
                    onChange={(e) => setSearchNewPostBrand(e.target.value)}
                    InputProps={{
                      style: {
                        height: '40px',
                        borderRadius: '8px',
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Box>

                {filteredBrandsNewPost?.map((brand) => (
                  <Box
                    display="flex"
                    key={brand?._id}
                    width="100%"
                    paddingLeft="1rem"
                    alignItems="center"
                    gap={1}
                    marginTop="0.3rem"
                    onClick={() => setSelectedBrandId(brand._id!)}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor:
                        selectedBrandId === brand?._id
                          ? 'rgba(255, 0, 122, 0.08)'
                          : 'white',
                      height: '56px',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 0, 122, 0.08)',
                      },
                    }}
                  >
                    <Avatar
                      name={brand?.name || ''}
                      backgroundColor={brand?.backgroundColor || undefined}
                      avatar={brand?.account?.avatar || undefined}
                      size={40}
                    />
                    <Typography
                      fontSize={selectedBrandId === brand?._id ? '15px' : '14px'}
                      sx={{
                        color: selectedBrandId === brand?._id ? '#C20B63' : 'black',
                        fontWeight: selectedBrandId === brand?._id ? 700 : 'normal',
                      }}
                    >
                      {brand?.name}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Divider
                sx={{
                  marginY: '10px',
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenShareInboxModal(false)}
                  data-testid="done-filters-button"
                >
                  {t('Close')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSelectBrand}
                  disabled={!selectedBrandId}
                  data-testid="select-brand-button"
                >
                  {t('Select')}
                </Button>
              </Box>
            </Stack>
          )}
        </form>
      </Dialog>

      <ShareInboxSettingsModal
        open={openPasswordModal}
        onClose={() => setOpenPasswordModal(false)}
        onConfirm={handlePasswordConfirm}
        mode="set" // Two password fields for setting a password
        brandId={selectedBrandId || ''} // Pass selected brand ID
      />

      <Dialog open={openSelectUser} onClose={handleCloseModalSelectUser}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '15px',
            cursor: 'pointer',
          }}
        >
          <IconButton onClick={handleCloseModalSelectUser}>
            <Close />
          </IconButton>
        </Box>

        <form
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'right',
            width: '100%',
          }}
          onSubmit={(e) => {
            e.preventDefault(); // Prevent form submission on Enter key press
          }}
        >
          <Stack direction="column" sx={{ width: '500px', p: 3 }}>
            <Typography fontWeight={700} fontSize="25px">
              {is4Posts
                ? t('Who do you want to create a Post for?')
                : t('Who do you want to create content for?')}
            </Typography>
            <Divider
              sx={{
                marginY: '10px',
              }}
            />
            <Box maxHeight="500px" overflow="auto">
              <Typography fontWeight={700} my={1}>
                {t('Brand')}
              </Typography>
              <Box width="100%" justifyContent="center">
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  placeholder={t('Search')}
                  onChange={(e) => setSearchNewPostBrand(e.target.value)}
                  InputProps={{
                    style: {
                      height: '40px',
                      borderRadius: '8px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Box>

              <Box
                sx={{
                  paddingLeft: '1.5rem',
                  background: 'rgba(236, 236, 236, 1)',
                  marginY: 1,
                  '&:last-child td, &:last-child th': { border: 0 },
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  gap: 1,
                }}
                onClick={handleOpenModal}
              >
                <AddIcon
                  sx={{
                    fontSize: '24px',
                  }}
                />
                <Typography fontSize={14}>{t('Create new brand')}</Typography>
              </Box>

              {filteredBrandsNewPost?.map((brand) => (
                <Box
                  display="flex"
                  key={brand?._id}
                  width="100%"
                  paddingLeft="1rem"
                  alignItems="center"
                  gap={1}
                  marginTop="0.3rem"
                  onClick={() => {
                    setSelectedBrandId(brand?._id!);
                  }}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor:
                      selectedBrandId === brand?._id
                        ? 'rgba(255, 0, 122, 0.08)'
                        : 'white',
                    height: '56px',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 0, 122, 0.08)',
                    },
                  }}
                >
                  <Avatar
                    name={brand?.name || ''}
                    backgroundColor={brand?.backgroundColor || undefined}
                    avatar={brand?.account?.avatar || undefined}
                    size={40}
                  />
                  <Typography
                    fontSize={selectedBrandId === brand?._id ? '15px' : '14px'}
                    sx={{
                      color: selectedBrandId === brand?._id ? '#C20B63' : 'black',
                      fontWeight: selectedBrandId === brand?._id ? 700 : 'normal',
                    }}
                  >
                    {brand?.name}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Divider
              sx={{
                marginY: '10px',
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseModalSelectUser}
                data-testid="done-filters-button"
              >
                {t('Close')}
              </Button>{' '}
              <Button
                variant="contained"
                color="primary"
                onClick={async (e) => {
                  setFetching(true); // Start loader
                  if (is4Posts && selectedBrandId) {
                    let progressInterval: NodeJS.Timeout;

                    // Set loader and initialize progress tracking
                    setLoadingAutomation(true);
                    setProgress(0); // Reset progress to 0
                    setOpenSelectUser(false); // Close the brand selection modal after the API call finishes

                    // Simulate progress over 17 seconds
                    const totalTime = 40 * 1000; // 40 seconds in milliseconds
                    const intervalTime = 500; // Update every 0.5 seconds
                    let elapsedTime = 0;

                    progressInterval = setInterval(() => {
                      setProgress((prevProgress) => {
                        elapsedTime += intervalTime;
                        const newProgress = (elapsedTime / totalTime) * 100; // Calculate progress percentage
                        return newProgress >= 100 ? 100 : newProgress; // Stop at 100%
                      });

                      if (elapsedTime >= totalTime) {
                        clearInterval(progressInterval); // Stop updating after 17 seconds
                      }
                    }, intervalTime);

                    // Call the mutation for creating posts
                    await createPostsBrand({ variables: { brandId: selectedBrandId } });

                    // Ensure the progress reaches 100% when the mutation completes
                    clearInterval(progressInterval);
                    setProgress(100);
                  } else {
                    setOpenSelectUser(false);
                    setOpenNewPost(true);
                  }
                }}
                disabled={!selectedBrand}
                data-testid="done-filters-button"
              >
                {t('Select')}
              </Button>
            </Box>
          </Stack>
        </form>
      </Dialog>
      {loading && !fetching && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      {view === ViewOptions.grid && (
        <>
          {!loading && !dataBrands.length && !brandId && (
            <DemoCard handleOpenModal={handleOpenModal} />
          )}
          {!loading &&
            // !!dataBrands.length &&
            !posts.length &&
            !postExists &&
            !brandId &&
            !isFilterActive && <PostDemoCard handleModalNewPost={handleModalNewPost} />}

          {!!posts.length && (
            <>
              {filter.lifecycleStates?.length === 1 &&
                filter.lifecycleStates[0] === PostLifecycleState.Discarded && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAll && postsToDelete.length === posts.length}
                        />
                      }
                      label="Select all discarded posts"
                      onChange={handleSelectAll}
                    />
                    <Button
                      onClick={() => {
                        setOpenModalDelete(true);
                      }}
                      disabled={!postsToDelete.length}
                      variant="contained"
                      sx={{
                        backgroundColor: '#D32F2F',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#D32F2F',
                        },
                      }}
                    >
                      Delete (
                      {selectAll ? data?.postsByShell.total : postsToDelete.length})
                    </Button>
                  </Stack>
                )}

              <MasonryContentBoard
                posts={posts}
                handleEditPost={handleEditPost}
                postsToDelete={postsToDelete}
                setPostsToDelete={setPostsToDelete}
                inboxLink={!!brandId}
              />
            </>
          )}
          {fetching && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      {!loading && view === ViewOptions.list && (
        <PostsTeamTable
          posts={posts.slice(page * limit, page * limit + limit)}
          total={data?.postsByShell?.total || 0}
          limit={limit}
          setLimit={setLimit}
          page={page}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          onPageChange={(page) => setPage(page)}
          handleEditPost={handleEditPost}
        />
      )}

      {view === ViewOptions.calendar && (
        <MyCalendar
          events={events || []}
          handleEditPost={handleEditPost}
          handleOpenNewPost={(date) => {
            setNewPostSchedule(date);
            handleModalNewPost();
          }}
          handleDateRangeChange={(start, end) => {
            setFilter({ ...filter, dateFrom: start, dateTo: end });
          }}
          handleDiscard={(postId) => {
            setDeletePostId(postId);
          }}
        />
      )}
      {deletePostId && (
        <DiscardPost postId={deletePostId} onCloseModal={() => setDeletePostId(null)} />
      )}
      <ConfirmationDialog
        title={t('Delete posts')}
        content={
          <>
            <Divider
              sx={{
                marginBottom: '10px',
              }}
            />
            {t(
              'Deleting this post will make it permanently unavailable. This action cannot be undone, and all associated posts will be lost forever. Do you wish to proceed?',
            )}
            <Divider
              sx={{
                marginTop: '10px',
              }}
            />
          </>
        }
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onConfirm={handleHardDeletePosts}
        okText={t('Delete')}
        deleteButton
        loading={loadingDelete}
      />
      {(!!openedPostId || openNewPost) && (
        <WebSocketProvider postId={openedPostId}>
          <PostProvider
            postId={openedPostId}
            newPostOptions={{
              brandId: selectedBrand?._id,
              brand: selectedBrand,
              schedule: newPostSchedule,
            }}
            isLinkInbox={!!brandId}
          >
            <PostEditorModal
              onClose={handlePostEditorClose}
              selectedBrand={selectedBrand}
              setSelectedBrandId={setSelectedBrandId}
              onReadMessages={() => {}}
              isLinkInbox={!!brandId}
            />
          </PostProvider>
        </WebSocketProvider>
      )}
    </Stack>
  );
};

export default PostsList;
