import { Close, Delete, Edit, Link, MoreVert } from '@mui/icons-material';
import {
  Box,
  Chip,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { User, UserRole } from '../../__generated__/graphql';
import { formatFullDateShort, getFullName, past30Days } from '../../utils';
import AvatarCell from '../Avatar/AvatarCell';
import { useContext, useState } from 'react';
import { AvatarGroup } from '../Avatar/AvatarGroup';
import Avatar from '../Avatar/Avatar';
import { useForm } from 'react-hook-form';
import { BrandContext } from '../../context/BrandContext';
import EditMemberModal from '../MembersComponents/EditMemberModal';
import MemberPopover from '../MembersComponents/MemberPopover';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { GENERATE_LINK_INVITATION } from '../../graphql/mutations';
import { SnackbarContext } from '../../context/SnackbarContext';

const HeaderCell = ({ text, sx = {} }: { text: string; sx?: SxProps }) => (
  <TableCell sx={{ color: 'black', fontWeight: 'bold', ...sx }}>
    <Typography fontSize="14px" fontWeight="bold">
      {text}
    </Typography>
  </TableCell>
);

interface Props {
  filteredMembers: User[];
  handleModalDelete: (userId: string) => void;
  handleSendInvite: (idSelected: string, brandManager: boolean) => Promise<boolean>;
  loading: boolean;
  loadingUpdateMember: boolean;
  handleEditMember: (
    userId: User,
    brands: string[],
    roleSelected: UserRole | null,
  ) => Promise<boolean>;
}

const MembersTable = ({
  filteredMembers,
  handleModalDelete,
  handleSendInvite,
  loading,
  handleEditMember,
  loadingUpdateMember,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [memberSelected, setMemberSelected] = useState<User | null>(null);
  const [isBrandManager, setIsBrandManager] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalChangeRole, setOpenModalChangeRole] = useState(false);
  const { dataBrandsMemberEditor } = useContext(BrandContext);
  const { user } = useContext(AuthContext);
  const [generateLinkInvitation] = useMutation(
    GENERATE_LINK_INVITATION,
  );
  const { setSuccessMessage } = useContext(SnackbarContext);

  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      brands: [] as string[],
    },
    values: {
      brands: memberSelected?.role.includes(UserRole.BrandManager)
        ? dataBrandsMemberEditor.map((brand) => brand._id)
        : memberSelected?.brandsManaged?.map((brand) => brand?._id) || [],
    },
  });

  const onSubmit = async (input: any) => {
    const role = !isBrandManager
      ? null
      : input.brands.length === dataBrandsMemberEditor.length
      ? UserRole.BrandManager
      : UserRole.LimitedBrandManager;

    const res = await handleEditMember(memberSelected!, input.brands, role);
    if (res) handleCloseModalChangeRole();
  };

  const handleOpenModalChangeRole = () => {
    setOpenModalChangeRole(true);
  };

  const handleCloseModalChangeRole = () => {
    setOpenModalChangeRole(false);
    reset();
  };

  const handleOpenModalAllBrands = (member: User) => {
    setOpenModal(true);
    setMemberSelected(member);
  };

  const handleCloseModalAllBrands = () => {
    setOpenModal(false);
    setMemberSelected(null);
  };

  const handleInvite = async () => {
    const res = await handleSendInvite(
      memberSelected?._id || '',
      memberSelected?.role?.includes(UserRole.BrandManager) ||
        memberSelected?.role.includes(UserRole.LimitedBrandManager) ||
        false,
    );
    if (res) handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMemberSelected(null);
  };

  const handleGetLink = async (member: User) => {
    const { data } = await generateLinkInvitation({
      variables: {
        userId: member._id,
      },
    });

    if (data?.generateLinkInvite?.success) {
      navigator.clipboard.writeText(data?.generateLinkInvite?.inviteLink as string);
      setSuccessMessage(t('Link copied to your clipboard'));
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '20px',
        }}
      >
        <Table
          sx={{
            width: '100%',
          }}
        >
          <TableHead>
            <TableRow>
              <HeaderCell text={t('Name')} />
              <HeaderCell text={t('Rol')} />
              <HeaderCell text={t('Assigned brands')} />
              <HeaderCell text={t('Date added')} />
              <HeaderCell text={t('Invitation')} />
              <HeaderCell text={t('Actions')} />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMembers
              ?.sort((a, b) => {
                if (a.createdOn > b.createdOn) return -1;
                if (a.createdOn < b.createdOn) return 1;

                return 0;
              })
              .map((member) => (
                <TableRow
                  key={member._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 'none' },
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  }}
                  data-testid="user-row"
                >
                  <AvatarCell
                    avatar={member?.avatar || ''}
                    name={getFullName(member) || ''}
                    lastName={member.lastName || ''}
                    email={member.email || ''}
                  />
                  <TableCell>
                    <Chip
                      aria-describedby={memberSelected?._id}
                      label={
                        member.role.includes(UserRole.BrandManager) ||
                        member.role.includes(UserRole.LimitedBrandManager)
                          ? t('Brand Manager')
                          : t('Member')
                      }
                      variant="filled"
                      sx={{
                        backgroundColor:
                          member.role.includes(UserRole.BrandManager) ||
                          member.role.includes(UserRole.LimitedBrandManager)
                            ? 'hsla(231, 44%, 74%, 0.4)'
                            : 'hsla(201, 98%, 41%, 0.08)',
                        color: 'hsla(0, 0%, 0%, 0.87)',
                        fontWeight: 600,
                      }}
                      size="medium"
                    />
                  </TableCell>
                  <TableCell>
                    {member.role.includes(UserRole.BrandManager) && t('All')}
                    {!member.role.includes(UserRole.BrandManager) &&
                      member.role.includes(UserRole.LimitedBrandManager) &&
                      !!member.brandsManaged?.length && (
                        <AvatarGroup
                          max={3}
                          titleTooltip="View all brands"
                          avatars={
                            member.brandsManaged?.map((brand) => ({
                              id: brand?._id || '',
                              name: brand?.name || '',
                              avatar: brand?.account?.avatar || '',
                              backgroundColor: brand?.backgroundColor || '',
                              active: true,
                            })) as any
                          }
                          onClickSurplus={() => {
                            handleOpenModalAllBrands(member);
                          }}
                          size={40}
                        />
                      )}
                    {member.role.includes(UserRole.LimitedBrandManager) &&
                      !member.brandsManaged?.length &&
                      t('None')}

                    {!member.role.includes(UserRole.BrandManager) &&
                      !member.role.includes(UserRole.LimitedBrandManager) &&
                      '-'}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {formatFullDateShort(member.createdOn)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      aria-describedby={memberSelected?._id}
                      label={
                        member.uid
                          ? t('Accepted')
                          : past30Days(member.inviteSentOn)
                          ? t('Pending')
                          : t('Expired')
                      }
                      variant="filled"
                      sx={{
                        backgroundColor: member.uid
                          ? 'hsla(123, 46%, 34%, 0.3)'
                          : past30Days(member.inviteSentOn)
                          ? 'hsla(36, 100%, 50%, 0.3)'
                          : 'hsla(14, 100%, 70%, 0.7)',
                        color: 'hsla(0, 0%, 0%, 0.87)',
                        fontWeight: 600,
                      }}
                      size="medium"
                      deleteIcon={
                        member.uid || user?.shell?.noSendEmail ? undefined : <MoreVert />
                      }
                      onDelete={
                        member.uid || user?.shell?.noSendEmail
                          ? undefined
                          : (e) => {
                              setMemberSelected(member);
                              handleClick(e);
                            }
                      }
                    />
                    {Boolean(anchorEl) && memberSelected?._id === member._id && (
                      <MemberPopover
                        memberSelected={memberSelected}
                        filteredMembers={filteredMembers}
                        handleInvite={handleInvite}
                        loading={loading}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        member={member}
                      />
                    )}
                    {!member.uid && (
                      <Tooltip title={t('Get invitation link')}>
                        <IconButton
                          onClick={() => {
                            handleGetLink(member);
                          }}
                        >
                          <Link />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setMemberSelected(member);
                        handleOpenModalChangeRole();
                        setIsBrandManager(
                          member.role.includes(UserRole.BrandManager) ||
                            member.role.includes(UserRole.LimitedBrandManager)
                            ? true
                            : false,
                        );
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleModalDelete(member._id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openModal}
        onClose={handleCloseModalAllBrands}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '555px' },
            height: 'auto',
            minHeight: '300px',
            padding: 2,
          },
        }}
      >
        <Stack>
          <Stack>
            <Typography fontWeight={700} fontSize={25}>
              All brands assigned to: {getFullName(memberSelected)}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseModalAllBrands}
              disabled={loading}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </Stack>
          <Divider
            sx={{
              marginY: '1rem',
            }}
          />
          <Box maxHeight={400} overflow="auto">
            {memberSelected?.brandsManaged?.map((brand) => (
              <Box
                display="flex"
                key={brand?._id}
                width="100%"
                alignItems="center"
                gap={1}
                marginTop="0.3rem"
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  height: '56px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 0, 122, 0.08)',
                  },
                }}
              >
                <Avatar
                  name={brand?.name || ''}
                  backgroundColor={brand?.backgroundColor || undefined}
                  avatar={brand?.account?.avatar || undefined}
                  size={50}
                />
                <Typography
                  fontSize={'14px'}
                  sx={{
                    color: 'black',
                    fontWeight: 'normal',
                  }}
                >
                  {brand?.name}
                </Typography>
              </Box>
            ))}
          </Box>

          <Divider
            sx={{
              marginY: '1rem',
            }}
          />
        </Stack>
      </Dialog>

      <EditMemberModal
        openModalChangeRole={openModalChangeRole}
        handleCloseModalChangeRole={handleCloseModalChangeRole}
        dataBrands={dataBrandsMemberEditor}
        memberSelected={memberSelected}
        isBrandManager={isBrandManager}
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        loading={loading}
        setIsBrandManager={setIsBrandManager}
        loadingUpdateMember={loadingUpdateMember}
      />
    </>
  );
};

export default MembersTable;
