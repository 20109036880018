import { useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { suggestion } from '../components/TextEditor/Suggestion';
import { UsePostReturnType } from './usePost';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { EXTRACT_THUMBNAIL_DATA } from '../graphql/mutations';
import {
  ExtractThumbnailDataMutation,
  Post,
  PostContentMedia,
  Tag,
} from '../__generated__/graphql';
import { AuthContext } from '../context/AuthContext';
import { uploadThumbnailMedia, extractInnerText, extractUrlsFromText, cleanHtmlHandler } from '../utils';
import useUnsavedChangesWarning from './useUnsavedChangesWarning';
interface UsePostEditorProps {
  postState: UsePostReturnType;
  content: string;
  editable: boolean;
  postRef: React.MutableRefObject<Post> | undefined;
  onChange?: (html: string, json: any, action?: Tag) => void;
  insights?: boolean;
}

export const usePostEditor = ({
  postState,
  editable,
  postRef,
  onChange,
  insights = false,
}: UsePostEditorProps): ReturnType<typeof useEditor> => {
  const [extractMetaTags] = useMutation(EXTRACT_THUMBNAIL_DATA);
  const [thumbnails, setThumbnails] = useState<Map<string, ExtractThumbnailDataMutation>>(
    new Map(),
  );
  const [currentThumbnailUrl, setCurrentThumbnailUrl] = useState<string | null>(null);
  const { user } = useContext(AuthContext);
  const handleLoadingThumbnail = postState.handlers.handleLoadingThumbnail;
  const handleErrorThumbnail = postState.handlers.handleErrorThumbnail;

  async function fetchMetaTags(url: string) {
    if (thumbnails.has(url)) {
      return thumbnails.get(url)?.extractThumbnailData;
    }

    handleLoadingThumbnail(true);

    const response = await extractMetaTags({
      variables: { url },
      onCompleted: (data) => {
        setThumbnails(new Map(thumbnails).set(url, data));
        if (!data?.extractThumbnailData) {
          handleErrorThumbnail(true);
          postState.handlers.handleShowErrorThumbnail(true);
        }

        handleLoadingThumbnail(false);
        return data;
      },
      onError: (error) => {
        handleErrorThumbnail(true);
        postState.handlers.handleShowErrorThumbnail(true);
        handleLoadingThumbnail(false);
      },
    });

    if (response) {
      return response.data?.extractThumbnailData;
    }

    return null;
  }

  async function handleThumbnail(htmlContent: string, paste: boolean = false) {
    if (!!postState.post.content?.media?.length) return;

    if (
      postState.post.content?.thumbnail?.title &&
      postState.status.showThumbnail &&
      paste
    )
      return;

    if (!user) return;
    const urls = extractUrlsFromText(htmlContent, paste, user);
    const url = urls[0] as string;
    setCurrentThumbnailUrl(url);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (!url || urls.length === 0 || (url !== currentThumbnailUrl && currentThumbnailUrl))
      return;
    //Primer caso, no tengo thumbnail
    if (!postState.post.content?.thumbnail?.title) {
      if (postState.status.thumbnailData?.source === url) {
        const { __typename: _, media, ...thumbnailData } = postState.status.thumbnailData;
        const { __typename: __, ...thumbailMedia } = media as PostContentMedia;
        postState.handlers.handleUploadThumbnail({
          media: thumbailMedia,
          ...thumbnailData,
        });

        return;
      }

      const metaTags = await fetchMetaTags(url);
      if (metaTags) {
        const thumbnailMedia = await uploadThumbnailMedia(metaTags?.imageBlob || '');

        const thumnailData = {
          media: thumbnailMedia,
          source: url,
          title: metaTags.title,
          description: metaTags.description,
        };

        // if (fetchThumbnail) fetchThumbnail(thumnailData);
        postState.handlers.handleUploadThumbnail(thumnailData);
      }
      return;
    }

    // Segundo caso: no muestro thumbnail y la URL es distinta a la que tengo
    if (
      !postState.status.showThumbnail &&
      url !== postState.post.content?.thumbnail?.source
    ) {
      const metaTags = await fetchMetaTags(url);
      if (metaTags) {
        const thumbnailMedia = await uploadThumbnailMedia(metaTags?.imageBlob || '');
        postState.handlers.handleUploadThumbnail({
          media: thumbnailMedia,
          source: url,
          title: metaTags.title,
          description: metaTags.description,
        });
      }
      return;
    }

    // Tercer caso: tengo thumbnail y lo estoy mostrando
    if (postState.status.showThumbnail && postState.post.content?.thumbnail?.title) {
      return;
    }

    // Cuarto caso: tengo thumbnail y no lo estoy mostrando, pero la URL es la misma
    if (
      typeof url === 'string' &&
      typeof postState.post.content?.thumbnail?.source === 'string' &&
      url === postState.post.content?.thumbnail?.source
    ) {
      postState.handlers.handleShowThumbnail();
      return;
    }
  }

  const hasUnsavedChanges =
    (!!postState.post.content?.body ||
      !!postState.post.content?.media ||
      !!postState.post.title) &&
    (extractInnerText(postState.postRef?.current.content?.body as string) !==
      extractInnerText(postState.status?.initialPostBodyRef.current || '') ||
      !!postState.post.title);

  useUnsavedChangesWarning(hasUnsavedChanges);

  const editor = useEditor({
    content: postRef?.current?.content?.body || '',
    editorProps: {
      transformPastedHTML: cleanHtmlHandler,
    },
    extensions: [
      Document,
      Text,
      History.extend({
        addKeyboardShortcuts() {
          return {
            'Mod-z': () => this.editor.commands.undo(),
            'Mod-y': () => this.editor.commands.redo(),
            'Mod-Shift-z': () => this.editor.commands.redo(),
          };
        },
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: 'tiptap-p',
        },
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
      }),
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => this.editor.commands.setHardBreak(),
          };
        },
      }),
    ],
    autofocus: true,
    editable,
    injectCSS: false,
    onUpdate: ({ editor, transaction }) => {
      const htmlContent = editor.getHTML();
      const isPaste = transaction.getMeta('uiEvent') === 'paste';
      const jsonContent = editor.getJSON();

      if (onChange) {
        onChange(editor.getHTML(), editor.getJSON());
      }

      if (postRef?.current) {
        if (!postRef.current.content) {
          postRef.current.content = {}; // Inicializa `content` si no existe
        }

        postRef.current.content.body = htmlContent;
        postRef.current.content.json = jsonContent;
      }

      if (isPaste) {
        handleThumbnail(htmlContent, true);
      } else {
        handleThumbnail(htmlContent);
      }
    },
  });

  useEffect(() => {
    if (!postRef?.current?._id && postRef?.current?.content?.body && !insights) {
      editor?.commands.clearContent();
      postRef.current.content.body = '';
      postRef.current.content.json = null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRef?.current?._id]);

  return editor;
};
